const themes = [];

const defaultName = 'default';

const currentBrand = themes.find(item => {
    return location.hostname === item.hostname;
});

function setCssVars(theme) {
    

    const root = document.documentElement;

    for (let key in theme){
        root.style.setProperty(`--${key}`, theme[key]);
    }

    root.style.setProperty(`--hueString`, `${theme.mainHue}deg`);
    root.style.setProperty(`--hueStringNegavtive`, `-${theme.mainHue}deg`);
}

function setFavicon(id){
    const link = document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'icon';
    link.href = `${location.protocol}//${location.host}/assets/${id}/img/favicon.ico`;
    document.getElementsByTagName('head')[0].appendChild(link);
}

function removeLegacyStyles(){
    if (window.CSS && CSS.supports('color', 'var(--primary)')) {
        const legacyNotice = document.getElementById("legacy_notice");
        const legacyStyles = document.getElementById("legacy_styles");
        legacyNotice.remove();
        legacyStyles.remove();
    }
}

const userSetDarkMode = () => JSON.parse(localStorage.getItem('darkMode'));
const darkModeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

darkModeMediaQuery.addListener((e) => {
    const darkMode = e.matches;
    if (userSetDarkMode() === null){
        document.documentElement.dataset.darkMode = darkMode;
    }
});

userSetDarkMode() === null
    ? document.documentElement.dataset.darkMode = darkModeMediaQuery.matches
    : document.documentElement.dataset.darkMode = userSetDarkMode();


function currentTheme() {
    const brandName = currentBrand ? currentBrand.name : defaultName;

    const brandJSON = require(`@/utils/themes/${brandName}.json`);
    brandJSON.isBranded = Boolean(currentBrand) || brandName !== 'default';

    removeLegacyStyles();
    setCssVars(brandJSON.theme);
    setFavicon(brandName);

    window.brand = brandJSON;

    return brandJSON;
}

export default currentTheme();