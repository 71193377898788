export default [
    {
        name: 'Management', root: '/management', routeName: 'management.overview', icon: 'management',
        children: [
            { name: 'Overview', routeName: 'management.overview'},
            { name: 'Devices', routeName: 'management.devices', permission: 'view_devices' },
            { name: 'Hotspots', routeName: 'management.hotspots', permission: 'view_device_hotspots' },
            { name: 'Wi-Fi', routeName: 'management.wifi', permission: 'view_wireless_information' },
            { name: 'Dynamic DNS', routeName: 'management.ddns', permission: 'view_device_dynamic_dns' },
            { name: 'Files', routeName: 'management.files', permission: 'view_files' },
            { name: 'Reports', routeName: 'reports.single', permission: 'view_reports', root: '/reports' },
            // { name: 'Speedtest', routeName: 'management.speedtest', permission: 'view_device_speedtests' },
        ]
    },
    {
        name: 'RMS Connect', root: '/remote', routeName: 'remote.telnet', icon: 'server',
        children: [
            { name: 'Remote telnet', routeName: 'remote.telnet', permission: 'view_device_remote_access' },
            { name: 'Remote sftp', routeName: 'remote.sftp', permission: 'view_device_remote_access' },
            { name: 'Remote desktop', routeName: 'remote.desktop', permission: 'view_device_remote_access' },
            { name: 'Remote ssh', routeName: 'remote.ssh', permission: 'view_device_remote_access' },
            { name: 'Remote http(s)', routeName: 'remote.http', permission: 'view_device_remote_access' },
            // { name: 'Remote mobile devices', routeName: 'remote.mobile', permission: 'view_device_remote_access' }
        ]
    },
    // {
    //     name: 'RMS VPN', root: '/vpn', routeName: 'vpn.hubs', icon: 'shield',
    //     children: [
    //         { name: 'VPN hubs', routeName: 'vpn.hubs', permission: 'view_vpn_hubs'}
    //     ]
    // },
    {
        name: 'Users', root: '/users', routeName: 'users', icon: 'users',
        children: [
            { name: 'Users', routeName: 'users', permission: 'view_users' },
            { name: 'Companies', routeName: 'users.companies', permission: 'view_companies' },
            { name: 'Roles & permissions', routeName: 'users.roles', permission: 'view_roles' },
        ]
    },
    {
        name: 'Services', root: '/services', routeName: 'services.monitoring', icon: 'services',
        children: [
            { name: 'Management', routeName: 'services.monitoring', permission: 'view_devices' },
            { name: 'RMS Connect history', routeName: 'services.connect', root: '/rms-connect-history', permission: 'view_device_remote_access' },
            // { name: 'Connect/VPN', routeName: 'services.connect', permission: 'view_company_data_history', root: '/rms-connect' },
        ]
    },
    {
        name: 'Admin', root: '/statistics', routeName: 'statistics.general', icon: 'bar_chart',
        children: [
            { name: 'Statistics', routeName: 'statistics.general', permission: 'view_statistics', root: '/statistics' },
            { name: 'System services', routeName: 'system.services', permission: 'super_admin' },
            { name: 'Global Firmware', routeName: 'files.global', permission: 'super_admin' }
        ]
    }
];