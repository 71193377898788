import Vue from 'vue'
import Router from 'vue-router'
import sessionService from "@/services/sessionService";
import {removeTooltip} from '@/directives/tooltip';
const defaultRoute = '/management';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'link-active',
    base: '/',
    // scrollBehavior () {
    //     return { x: 0, y: 0 }
    // },
	routes: [
        // Main routes
        {
            path: '/management',
            name: 'management.overview',
            meta: {},
            component: () => import('@/views/main/ManagementOverview.vue' /* webpackChunkName: "overview" */)
        },
        {
            path: '/management/devices',
            name: 'management.devices',
            meta: {permission: 'view_devices'},
            component: () => import('@/views/main/ManagementDevices.vue' /* webpackChunkName: "devices" */)
        },

        {
            path: '/management/hotspots',
            name: 'management.hotspots',
            meta: {permission: 'view_device_hotspots'},
            component: () => import('@/views/main/ManagementHotspots.vue' /* webpackChunkName: "hotspots" */)
        },
        {
            path: '/management/wifi',
            name: 'management.wifi',
            meta: {permission: 'view_wireless_information'},
            component: () => import('@/views/main/ManagementWifi.vue' /* webpackChunkName: "wifi" */)
        },
        {
            path: '/management/ddns',
            name: 'management.ddns',
            meta: {permission: 'view_devices'},
            component: () => import('@/views/main/ManagementDDNS.vue' /* webpackChunkName: "ddns" */),
        },
        {
            path: '/management/tag/:id',
            name: 'management.tag_chart',
            meta: {permission: 'view_tags'},
            component: () => import('@/views/main/ManagementTagChart.vue' /* webpackChunkName: "tags" */)
        },
        // {
        //     path: '/management/speetest',
        //     name: 'management.speedtest',
        //     meta: {permission: 'view_device_speedtests'},
        //     component: () => import('@/views/main/ManagementSpeedtest.vue' /* webpackChunkName: "speedtest" */),
        // },
        // Remote routes
        {
            path: '/remote/desktop',
            name: 'remote.desktop',
            meta: { permission: 'view_device_remote_access'},
            component: () => import('@/views/main/RemoteDesktop.vue' /* webpackChunkName: "remote" */),
        },
        {
            path: '/remote/ssh',
            name: 'remote.ssh',
            meta: { permission: 'view_device_remote_access'},
            component: () => import('@/views/main/RemoteSSH.vue' /* webpackChunkName: "remote" */),
        },
        {
            path: '/remote/telnet',
            name: 'remote.telnet',
            meta: { permission: 'view_device_remote_access'},
            component: () => import('@/views/main/RemoteTelnet.vue' /* webpackChunkName: "remote" */),
        },
        {
            path: '/remote/sftp',
            name: 'remote.sftp',
            meta: { permission: 'view_device_remote_access'},
            component: () => import('@/views/main/RemoteSFTP.vue' /* webpackChunkName: "remote" */),
        },
        {
            path: '/remote/http',
            name: 'remote.http',
            meta: { permission: 'view_device_remote_access'},
            component: () => import('@/views/main/RemoteHTTP.vue' /* webpackChunkName: "remote" */),
        },
        // {
        //     path: '/remote/mobile',
        //     name: 'remote.mobile',
        //     meta: { permission: 'view_device_remote_access' },
        //     component: () => import('@/views/main/RemoteMobile.vue' /* webpackChunkName: "remote" */),
        // },
        // VPN routes
        // {
        //     path: '/vpn/hubs',
        //     name: 'vpn.hubs',
        //     meta: { permission: 'view_vpn_hubs'},
        //     component: () => import('@/views/main/VPNHubs.vue' /* webpackChunkName: "vpn" */),
        // },

        // User routes
        {
            path: '/users',
            name: 'users',
            meta: {permission: 'view_users'},
            component: () => import('@/views/main/Users.vue' /* webpackChunkName: "users" */)
        },
        {
            path: '/users/companies',
            name: 'users.companies',
            meta: {permission: 'view_companies'},
            component: () => import('@/views/main/UsersCompanies.vue' /* webpackChunkName: "companies" */)
        },
        {
            path: '/users/roles',
            name: 'users.roles',
            meta: {permission: 'view_roles'},
            component: () => import('@/views/main/UsersRoles.vue' /* webpackChunkName: "roles" */)
        },
        // Files routes
        {
            path: '/management/files',
            name: 'management.files',
            meta: { permission: 'view_files' },
            component: () => import('@/views/main/ManagementFiles.vue' /* webpackChunkName: "files" */)
        },
        {
            path: '/files/global/firmware',
            name: 'files.global',
            meta: {permission: 'super_admin'},
            component: () => import('@/views/main/FilesGlobal.vue' /* webpackChunkName: "files" */)
        },
            // Reports routes
        {
            name: 'reports',
            path: '/reports/single',
            meta: {permission: 'view_reports'},
            component: () => import('@/views/report/Reports.vue' /* webpackChunkName: "reports" */),
            children: [
                {
                    name: 'reports.single',
                    path: '/reports/single',
                    meta: {permission: 'view_reports'},
                    component: () => import('@/views/report/SingleReports.vue' /* webpackChunkName: "reports" */)
                },
                {
                    name: 'reports.periodic',
                    path: '/reports/periodic/day',
                    meta: {permission: 'view_reports'},
                    component: () => import('@/views/report/PeriodicReports.vue' /* webpackChunkName: "reports" */),
                    children: [
                        {
                            name: 'reports.periodic.day',
                            path: '/reports/periodic/day',
                            meta: {permission: 'view_reports'},
                            component: () => import('@/views/report/DayReports.vue' /* webpackChunkName: "reports" */)
                        },
                        {
                            name: 'reports.periodic.week',
                            path: '/reports/periodic/week',
                            meta: {permission: 'view_reports'},
                            component: () => import('@/views/report/WeekReports.vue' /* webpackChunkName: "reports" */)
                        },
                        {
                            name: 'reports.periodic.month',
                            path: '/reports/periodic/month',
                            meta: {permission: 'view_reports'},
                            component: () => import('@/views/report/MonthReports.vue' /* webpackChunkName: "reports" */)
                        }
                    ]
                }
            ]
        },
        {
            path: '/report/generated/:id',
            name: 'report.generated',
            meta: { permission: 'view_reports'},
            component: () => import('@/views/report/ReportInfo.vue' /* webpackChunkName: "reports" */)
        },
        // Services routes
        {
            path: '/services/device-monitoring',
            name: 'services.monitoring',
            meta: {permission: 'view_devices'},
            component: () => import('@/views/main/ServicesMonitoring.vue' /* webpackChunkName: "monitoring" */)
        },
        {
            path: '/services/rms-connect-history',
            name: 'services.connect',
            meta: { permission: 'view_device_remote_access'},
            component: () => import('@/views/main/ServicesConnectHistory.vue' /* webpackChunkName: "monitoring" */),
        },
        // {
        //     path: '/services/rms-connect',
        //     name: 'services.connect',
        //     meta: { permission: 'view_company_data_history'},
        //     component: () => import('@/views/main/ServicesConnect.vue' /* webpackChunkName: "codes" */),
        // },
        // {
        //     path: '/services/rms-connect/history/:type',
        //     name: 'service.connect-history',
        //     meta: {permission: 'view_company_data_history'},
        //     component: () => import('@/views/main/ServicesConnectHistory.vue' /* webpackChunkName: "codes" */)
        // },
        // {
        //     path: '/redeem-code',
        //     name: 'service.add-credits',
        //     component: () => import('@/views/RedeemCode.vue' /* webpackChunkName: "codes" */)
        // },
        // Statistics routes
        {
            name: 'statistics.main',
            path: '/statistics',
            meta: {permission: 'view_statistics'},
            component: () => import('@/views/statistics/Statistics.vue' /* webpackChunkName: "statistics" */),
            children: [
                {
                    name: 'statistics.general',
                    path: '/statistics',
                    meta: {permission: 'view_statistics'},
                    component: () => import('@/views/statistics/StatsGeneral.vue' /* webpackChunkName: "statistics" */)
                },
                {
                    name: 'statistics.connection-manager',
                    path: '/statistics/connection-manager',
                    meta: {permission: 'view_statistics'},
                    component: () => import('@/views/statistics/ConnectionManager.vue' /* webpackChunkName: "statistics" */)
                },
                {
                    name: 'statistics.months',
                    path: '/statistics/months-stats',
                    meta: {permission: 'view_statistics'},
                    component: () => import('@/views/statistics/StatsMonths.vue' /* webpackChunkName: "statistics" */)
                },
                {
                    name: 'statistics.server',
                    path: '/statistics/server-logs',
                    meta: {permission: 'view_statistics'},
                    component: () => import('@/views/statistics/StatsServerLogs.vue' /* webpackChunkName: "statistics" */)
                },
                {
                    name: 'statistics.action',
                    path: '/statistics/action-logs',
                    meta: {permission: 'view_statistics'},
                    component: () => import('@/views/statistics/StatsActionLogs.vue' /* webpackChunkName: "statistics" */)
                },
                {
                    name: 'statistics.devices',
                    path: '/statistics/online-devices',
                    meta: {permission: 'view_statistics'},
                    component: () => import('@/views/statistics/StatsOnlineDevices.vue' /* webpackChunkName: "statistics" */)
                },
                {
                    name: 'statistics.unique-devices',
                    path: '/statistics/unique-devices',
                    meta: {permission: 'view_statistics'},
                    component: () => import('@/views/statistics/StatsUniqueDevices.vue' /* webpackChunkName: "statistics" */)
                }
            ]
        },
        // Device info route
        {
            name: 'device.main',
            path: '/devices/:id',
            meta: {permission: 'view_devices'},
            component: () => import('@/views/device/DeviceMain.vue' /* webpackChunkName: "device_info" */),
            children: [
                {
                    name: 'device.info',
                    path: '/devices/:id',
                    component: () => import('@/views/device/DeviceInfo.vue' /* webpackChunkName: "device_info" */)
                },
                {
                    name: 'device.location',
                    path: '/devices/:id/location',
                    component: () => import('@/views/device/DeviceLocation.vue' /* webpackChunkName: "device_location" */)
                },
                {
                    name: 'device.metrics',
                    path: '/management/devices/:id/metrics/dynamic',
                    component: () => import('@/views/device/DeviceMetrics.vue' /* webpackChunkName: "device_metrics" */),
                    children: [
                        {
                            name: 'device.dynamic',
                            path: '/management/devices/:id/metrics/dynamic',
                            component: () => import('@/views/device/DeviceChart.vue' /* webpackChunkName: "device_metrics" */)
                        },
                        {
                            name: 'device.mobile-data',
                            path: '/management/devices/:id/metrics/mobile-data',
                            component: () => import('@/views/device/DeviceChart.vue' /* webpackChunkName: "device_metrics" */)
                        },
                        {
                            name: 'device.io',
                            path: '/management/devices/:id/metrics/io',
                            component: () => import('@/views/device/DeviceChart.vue' /* webpackChunkName: "device_metrics" */)
                        },
                        {
                            name: 'device.gps',
                            path: '/management/devices/:id/metrics/gps',
                            component: () => import('@/views/device/DeviceChart.vue' /* webpackChunkName: "device_metrics" */)
                        }
                    ]
                },
                {
                    name: 'device.usage',
                    path: '/devices/:id/data-usage',
                    component: () => import('@/views/device/DeviceUsage.vue' /* webpackChunkName: "device_charts" */)
                },
                {
                    name: 'device.files',
                    path: '/devices/:id/files-manager',
                    meta: {permission: 'super_admin'},
                    component: () => import('@/views/device/DeviceFiles.vue' /* webpackChunkName: "file_manager" */)
                },
                {
                    name: 'device.ports',
                    path: '/devices/:id/ports',
                    component: () => import('@/views/device/PortScan.vue' /* webpackChunkName: "port_scan" */)
                },
                {
                    name: 'device.hotspot',
                    path: '/devices/:id/hotspot',
                    component: () => import('@/components/table/expand/DeviceHotspots.vue')
                },
                {
                    name: 'device.wifi',
                    path: '/devices/:id/wifi',
                    component: () => import('@/components/table/expand/DeviceWifi.vue')
                },
                {
                    name: 'device.ddns',
                    path: '/devices/:id/ddns',
                    component: () => import('@/components/table/expand/DeviceDDNSData.vue')
                }
                
            ]
        },
        {
            name: 'system.services',
            path: '/system-services/',
            meta: {permission: 'super_admin'},
            component: () => import("@/views/SystemServices.vue" /* webpackChunkName: "services" */)
        },
        // Misc routes
        // {
        //     name: 'notifications.main',
        //     path: '/notifications',
        //     component: () => import("@/views/Notifications.vue" /* webpackChunkName: "notifications" */)
        // },
        {
            name: 'logout',
            path: '/logout',
            meta: {
                standalone: true
            },
            component: () => import("@/views/Logout.vue" /* webpackChunkName: "logout" */)
        },
        // Remote connect route
        {
            name: 'desktop.connect',
            path: '/rms-connect/desktop'
        },
        {
            name: 'ssh.connect',
            path: '/rms-connect/ssh'
        },
        {
            name: 'mobile.connect',
            path: '/rms-connect/mobile'
        },
        {
            name: 'sftp.connect',
            path: '/rms-connect/sftp'
        },
        {
            name: 'telnet.connect',
            path: '/rms-connect/telnet'
        },
        // Fallback route
        {
            path: '*',
            redirect: defaultRoute 
        }		
	]
});

router.beforeEach(async(to, from, next) => {
    if(['ssh.connect', 'desktop.connect'].includes(to.name) && to.query?.key){
        next();
    }
    else{ 
        let continuePath;
        const currentURL = new URL(location.href);
        const continue_url = currentURL.searchParams.get('continue_url');

        if(continue_url){
            try {
                continuePath = new URL(continue_url).pathname;
                window.history.replaceState({}, document.title, continuePath);
            } catch {
                window.history.replaceState({}, document.title, currentURL.pathname);
            }
            delete to.query.continue_url;
        }

        if(!sessionService.user){
            await sessionService.initSession();

        }

        const hasPermission = to.meta?.permission
            ? sessionService.hasPermission(to.meta.permission)
            : true;

        if(Vue.prototype.$theme.disabled_routes){
            if(Vue.prototype.$theme.disabled_routes.includes(to.name)) return next(defaultRoute);
        }

        removeTooltip();

        const pendingRequestsKeys = Object.keys(window.pendingRequests);
        
        if(pendingRequestsKeys.length){
            pendingRequestsKeys.forEach(url => {
                window.pendingRequests[url]?.cancelSource.cancel('Route changed');
                delete window.pendingRequests[url];
            });
        }
        
        removeTooltip();
        
        if(hasPermission){
            next(continuePath);
        }
        else{
            if(sessionService.isAuthenticated){
                Vue.prototype.$snack.show({text: "Access denied"});
                next(defaultRoute);
            }else{
                next(location.pathname+location.search);
            }
        }
    }
    
});

export default router;

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
// function lazyLoadView(AsyncView) {
//     const AsyncHandler = () => ({
//         component: AsyncView,
//         // A component to use while the component is loading.
//         loading: require('@views/_loading').default,
//         // Delay before showing the loading component.
//         // Default: 200 (milliseconds).
//         delay: 400,
//         // A fallback component in case the timeout is exceeded
//         // when loading the component.
//         error: require('@views/_timeout').default,
//         // Time before giving up trying to load the component.
//         // Default: Infinity (milliseconds).
//         timeout: 10000,
//     })

//     return Promise.resolve({
//         functional: true,
//         render(h, { data, children }) {
//             // Transparently pass any props or children
//             // to the view component.
//             return h(AsyncHandler, data, children)
//         },
//     })
// }