import dayjs from 'dayjs';
import roleOptions from '@/utils/roles';
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));
dayjs.extend(require('dayjs/plugin/relativeTime'));

const defaultValue = '-';
const timezone = localStorage.getItem('setTimeZone') || dayjs.tz.guess();


export function titleFilter(value){
    if(!value) return defaultValue;
    value = value.toString();
    value = value[0].toUpperCase() + value.slice(1);

    if(value === 'Wifi') return 'WiFi';

    if (value.match(/^([\d][g])$/)) {
        return value.toUpperCase();
    }

    const acronyms = ['gps', 'imei', 'ip', 'iccid', 'imsi', 'lac', 'mac', 'mnc', 'sim', 'wan', 'mcc', ' oc', 'pin', 'wlan', 'rscp', 'ecio', 'rsrp', 'sinr', 'rsrq', 'ddns'];
    acronyms.forEach(item => {
        if(item === 'ip'){
            if (value.toLowerCase().match(/ip\b/)){
                value = value.replace('ip', 'IP');
            }
        }
        else if (value.toLowerCase().includes(item)) {
            const regex = new RegExp(item, 'ig');
            value = value.replace(regex, item.toUpperCase());
        }
    });

    return value.replace(/_/g, " ");
}

export function formatCell(value, formatter){
    // if(!value) return defaultValue;
    
    if(formatter){
        switch (formatter){
            case 'toLocalTime':
                return toLocalTime(value);
            case 'usedCode': 
                return usedCodeFilter(value);
            case 'bytes':
                return bytesFilter(value);
            case 'temperature':
                return temperatureFilter(value);
            case 'uptime':
                return uptimeFilter(value);
            case 'signal':
                return signalFilter(value);
            case 'connectionType':
                return connectionTypeFilter(value);
            case 'mqtt':
                return mqttFilter(value);
            case 'isFaceliftFilter':
                return isFaceliftFilter(value);
            case 'simSlotFilter':
                return simSlotFilter(value);
            case 'analogFilter':
                return analogFilter(value);
            case 'capitalize':
                return capitalize(value);
            case 'uppercase':
                return upperCase(value);
            case 'ioFilter':
                return ioFilter(value);
            case 'currentLoopFilter':
                return currentLoopFilter(value)
            case 'relayFilter':
                return relayFilter(value);
            case 'titleFilter':
                return titleFilter(value)
        }
    }else{
        return value;
    }
}

export function toLocalTime(value, format){
    let defaultFormat;
    let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if(isSafari) {
        defaultFormat = format ? format?.replace(/-/g,'/') : 'YYYY/MM/DD HH:mm:ss';
    }
    else {
        defaultFormat = format || 'YYYY-MM-DD HH:mm:ss';
    }

    if (value && dayjs(value).isValid()){
        if(typeof value === 'number') value = unixToDate(value);
        return dayjs(value).add(dayjs().tz(timezone).utcOffset(), 'minutes').format(defaultFormat);
    }else{
        return defaultValue;
    }
}

export function statusFilter(value) {
    const text = ['Offline', 'Online', 'Pending'];
    return text[value] || 'Not Activated';
}

export function roleFilter(value) {
    if(!value || typeof value === 'number') return defaultValue;    
    return roleOptions[value]?.name || defaultValue;
}

export function usedCodeFilter(value) {
    if(value) { return 'Used'; }
    return 'Not Used';
}

export function isFaceliftFilter(value){
    return ['Not facelift device', 'Facelift device'][value] || defaultValue;
}

export function bytesFilter(bytes, decimals = 2) {
    const SIZES = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    for (var i = 0, r = Number(bytes), b = 1024; r >= b; i++) r /= b;
    return `${parseFloat(r.toFixed(decimals))} ${SIZES[i]}`;
}

export function pendingJobType(value = Number(value)){
    switch (value) {
        case 101:
            return "Backup upload";
        case 100:
            return "Firmware upload";
        default:
            return "Other";
    }
}

export function isMqtt(value = Number(value)) {
    if(value > 0) return 'MQTT';
    return 'VPN';
}

export function temperatureFilter(temp){
    if (temp) return `${temp / 10} °C`;
    return defaultValue;
}

export function formattedNumber(value, decimals = 2) {
    if(value) return Number(value).toFixed(decimals);
    return '0.00';
}

export function connectionTypeFilter(type) {
    if(!type) return defaultValue;
    if (["5G-NSA", "5G-SA"].includes(type)) return "5G";
    if (["5G-NSA", "5G-SA"].includes(type)) return "5G";
    if (["LTE", "CAT-M1", "FDD LTE", "TDD LTE"].includes(type)) return "4G";
    if (["WCDMA", "HSDPA", "HSUPA", "HSPA", "HSPA+", "HSDPA and HSUPA", "HSDPA and H", "HSDPA+HSUPA", "DC-HSPA+"].includes(type)) return "3G";
    if (["CDMA", "EDGE", "GPRS", "GSM", "CAT-NB1"].includes(type)) return "2G";

    return type;
}

export function uptimeFilter(value)  {
    let time = value;
    if(typeof time === 'string' && isNaN(time)) {
        let dateNow = toLocalTime(dayjs().utc().format('YYYY-MM-DD HH:mm:ss'));
        time = dayjs(dateNow).diff(toLocalTime(value), 'second');
    }
    if (!time)  return '-';
    let seconds = Number(time);
    var d = Math.floor(seconds / (3600 * 24));
    var h = Math.floor(seconds % (3600 * 24) / 3600);
    var m = Math.floor(seconds % 3600 / 60);
    var s = Math.floor(seconds % 3600 % 60);
    var dDisplay = d > 0 ? d + (d == 1 ? " d " : " d ") : "";
    var hDisplay = h > 0 ? h + (h == 1 ? " h " : " h ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " min " : " min ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " s" : " s") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
}

export function unixToDate(timeStamp) {
    // if(timeStamp) return new Date(timeStamp * 1000).toUTCString();
    // return defaultValue;
    if(timeStamp) return dayjs.unix(timeStamp).utc(false);

    return defaultValue;

}

export function mqttFilter(value){
    if(value > 0) return 'MQTT';
    return 'VPN';
}

export function signalFilter(signal){
    if(signal) return `${signal} dBm`;
    return defaultValue;
}

export function fromCamelCaseFilter(type){
    if(type){
        return type
            .replace(/([A-Z])/g, function (match) {
                return " " + match;
            })
            .replace(/^./, function (match) {
                return match.toUpperCase();
            });
    }
    else{
        return defaultValue;
    }
}

export function decimals(value, num = 2){
    if(!value) return defaultValue;
    return parseFloat(value).toFixed(num).replace(/[.,]00$/, "");
}

export function capitalize(value) {
    if (!value) return defaultValue;
    return value[0].toUpperCase() + value.slice(1);
}

export function cleanString(value){
    if(!value || typeof value !== 'string') return "";
    return value.replace(/_/g, " ").replace(/-/g, " ");
}

export function upperCase(value) {
    if(!value || typeof value !== 'string') return defaultValue;
    return value.replace(/_/g, " ").toUpperCase();
}

export function timeDuration(start, end){
    if(!start || !end) return defaultValue;
    const diff = dayjs(end).diff(dayjs(start));
    return uptimeFilter(diff);
}

export function simSlotFilter(value){
    const values = ['SIM 2', 'SIM 1', 'SIM 2'];
    return values[value] || defaultValue;
}

export function analogFilter(value){
    if(value && !isNaN(value)) {
        let number = value / 1000;
        return Number.isInteger(number) ? `${number} V` : `${number.toFixed(2)} V`;
    }
    if(value === 0){
        return `${value} V`;
    }
    return defaultValue;
}

export function ioFilter(value){
    const values = ['Low level', 'High level'];
    return values[value] || defaultValue;
}

export function relayFilter(value) {
    const values = ['Closed', 'Open'];
    return values[value] || defaultValue;
}

export function currentLoopFilter(val){
    if (!val) return defaultValue;
    return `${val * 1000} mA`;
}

export function fileTypeFilter(type){
    if(!type) return defaultValue;
    switch (type) {
        case 'firmware':
            return 'Firmware';
        case 'config':
            return 'Configuration';
        case 'eventslog':
            return 'Events log';
        case 'troubleshoot':
            return 'Troubleshoot';
        case 'device_task_manager_file':
            return 'Task manager file';
        default:
            return titleFilter(type);
    }
}

export function connectionManagerReason(id){
    if([0,10,12,20].includes(id)){
        return "Device has attempted to connect to the system.";
    }
    if(id == 14){
        return "Device could not connect to the system - device Monitoring was disabled.";
    }
    if(id == 15){
        return "Device could not connect to the system - device did not have an active credit.";
    }
    if(id == 7){
        return "Device could not connect to the system - there was a device with an identical serial number but different MAC address already registered.";
    }
    return defaultValue;
}