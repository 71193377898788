// import roles from '@/utils/roles';

const defaultsHints = {
    company: 'Which company the newly added device will belong to.',
    device_type: "Which type of device you wish to add. \n RUT - all RUT2, RUT3, RUT8, RUT9, RUTX and RUTM devices; \nTRB - all TRB1, TRB2 and TRB5 devices;\nTCR - all TCR1 devices;\nTAP - all TAP1 and TAP2 devices;\nTSW - TSW202 and TSW212 devices;\nOTD - all OTD1 devices.",
    auto_licence_enable: 'When monitoring is disabled, a device will appear offline and will not be monitored. This feature is used for saving data, as a device with disabled monitoring will not send data until monitoring is re-enabled in Services → Management page.',
    file_csv: 'CSV (Comma Separated Values) is a simple file format used to store tabular data. This file must contain a serial number and a MAC address, both separated by a comma. Each device must be in separate lines.',
    first_parameter: 'Select which parameter is first in your CSV file line.',
    separation_symbol: 'Select by which symbol your CSV parameters are separated.',
    mac_separator: 'Select how your MAC addresses are separated.',
    password: 'Must be atleast 8 characters long, include one uppercase letter and one digit',
};

const ioHintSuffix = `\r\rFor more detailed information about specifications please take a look at device’s WebUI section ‘Input/Output’, which can be found under ‘Services’ or refer to official documentation.`;

const appName = window.brand?.text?.appName;

export const hints = {
    fields: {
        sim_slot: 'SIM slot that is currently being used by the device.',
        model: 'Device model',
        mcc: `Mobile country code (MCC) consists of three decimal digits. Used in combination with MNC to uniquely identify a mobile network operator.`,
        lac: 'Location area code (LAC) is a unique number given to each location area within the cellular network. Used as a reference for the location of a mobile subscriber.',
        network_state: `Mobile network registration state of the device. When registered, home or roaming conditions will apply. If roaming, SIM will connect to any network it can detect, at home or abroad.`,
        course: 'Degrees where the device is currently travelling. Calculated using GPS coordinates',
        latitude: 'Device GPS latitude coordinates',
        longitude: 'Device GPS longitude coordinates',
        satellites: 'How many satellites are currently servicing the device',
        speed: 'Device movement speed calculated using device GPS coordinates over a period of time',
        accuracy: 'Accuracy of device GPS. Measured in metres',
        batch_number: 'Manufacturing batch number of device',
        wlan_mac: 'MAC address of Wireless LAN interface',
        company_name: 'Company which item belongs to',
        firmware: 'Firmware version of the device. Firmware files can be uploaded in Management → Files for further use.',
        connection_state: 'Mobile data connection state of the device.',
        connection_uptime: 'For how long this device has had a mobile data connection.',
        created_at: 'When this item was registered/created',
        last_update_at: 'Last time the system received any monitoring information about the device. This includes any and all dynamic, static, hotspot, wireless, I/O, GPS, DDNS information.',
        updated_at: 'When item information was edited',
        imei: 'International Mobile Equipment Identity. A unique number assigned to each individual device',
        dynamic_monitoring: `How often ${appName} automatically gets your device’s dynamic information. Can be changed in Management → Set monitoring update period`,
        static_monitoring: `How often ${appName} automatically gets your device’s static information. Can be changed in Management → Set monitoring update period`,
        io_monitoring: `How often ${appName} automatically gets your device’s input/output information. Can be changed in Management → Set monitoring update period.`,
        gps_monitoring: `How often ${appName} automatically gets your device’s GPS information. Can be changed in Management → Set monitoring update period`,
        hotspot_monitoring: `How often ${appName} automatically gets your device’s hotspot information. Can be changed in Management → Set monitoring update period`,
        gps_time: 'Time of each device’s current time zone. Is only available if device has GPS enabled.',
        mnc: 'Mobile network code (MNC) consists of two or three decimal digits. Used in combination with MCC to uniquely identify a mobile network operator.',
        pin_state: `PIN state of the device's SIM card. If ready, the SIM card in question has the correct PIN number entered.`,
        operator: 'The name of a mobile network operator that the device is connected to.',
        sim_state: `Device's SIM card state. If inserted, SIM card is ready to be used.`,
        router_uptime: 'How much time has passed since the last device start up.',
        product_code: 'Product code used to identify the type of device',
        fix_status: 'Current device GPS coordinate fix status. If “1”, device currently has GPS coordinates',
        hardware_revision: 'Manufactoring hardware revision of device',
        wan_state: 'WAN type of the device (Wired, Mobile, WiFi).',
        wan_ip: `IP address of device's current WAN.`,
        modem_model: 'Model of modem used in device',
        mobile_ip: `IP address of device's current mobile interface.`,
        modem_manufacturer: 'Manufacturer of the modem used in device',
        modem_firmware: 'Firmware used in each device’s modem',
        operator_number: `Combination of SIM card's MCC and MNC values used to uniquely identify a mobile network operator.`,
        iccid: 'Integrated circuit card identification number (ICCID) is a unique number that includes information about SIM card. Usually found on the SIM card itself or packaging materials.',
        mac: 'Device MAC address',
        last_connection_at: 'monitoring uptime',
        signal: 'Mobile signal strength of the device (dBm).',
        temperature: `Temperature of the device's modem (°C).`,
        serial: 'Device’s serial number',
        mqtt: 'Type of connection used by your device. Newer firmware versions use MQTT, while older ones use VPN',
        connection_type: 'Mobile connection type of the device (5G, 4G, 3G, 2G).',
        license_expire_date: 'Date of when a credit will expire for each device. Credits expire 30 days after activation.',
        imsi: 'International Mobile Subscriber Identity. A number that uniquely identifies a user in a cellular network.',
        cell_id: 'Number used to uniquely identify each base transceiver station within a GSM network.',
        rx_day: 'How many bytes have been received in the past day for every SIM card. Readable format provided with 1 KB = 1024 Bytes conversion rule.',
        rx_week: 'How many bytes have been received in the past week for every SIM card. Readable format provided with 1 KB = 1024 Bytes conversion rule.',
        rx_month: 'How many bytes have been received in the past month for every SIM card. Readable format provided with 1 KB = 1024 Bytes conversion rule.',
        tx_day: 'How many bytes have been transmitted in the past day for every SIM card. Readable format provided with 1 KB = 1024 Bytes conversion rule.',
        tx_week: 'How many bytes have been transmitted in the past week for every SIM card. Readable format provided with 1 KB = 1024 Bytes conversion rule.',
        tx_month: 'How many bytes have been transmitted in the past month for every SIM card. Readable format provided with 1 KB = 1024 Bytes conversion rule.',
        is_facelift: 'Shows if each device is a facelift model.',
        monitoring_enabled: "This switch controls monitoring of each device. When monitoring is disabled, a device will appear offline and will not be monitored. After re-enabling monitoring, it may take a few minutes for a device to become online again. This feature is used for saving data, as a device with disabled monitoring will not send data until monitoring is re-enabled.",
        auto_extend_credit: "This switch controls if a credit will be automatically used by a device after each 30 day period. When disabled, after a credit becomes invalid, the device in question will become offline until a credit is manually assigned using the 'Service' switch. Keep in mind, even if 'Monitoring' is disabled, a credit will still be used if 'Auto Extend' is enabled.",
        credit_enabled: "This switch controls your credit activation and is used to manually use a credit and enable the service to a device if it wasn't automatically enabled after 30 days (due to Auto Extend being disabled). Cannot be disabled if a device has used a credit the past 30 days. This feature is used when you want to only manually use credits for each device.",
        can_use_parent_license: "This switch controls if your parent company's credits will be automatically used by your devices in case of your company having insufficient credits to supply all of your devices. Parent company credits get used only when your own company credit balance is 0. If a parent company has no available credits to give but has its own parent company with 'Use parent credits' enabled, credits will not be assigned to your device.",
        digital_input: `Digital input state. Associated pins are located in pin array connector for the devices that support digital input. ${ioHintSuffix}`,
        digital_isolated_input: `Digital galvanically isolated input state. Associated pins are located in pin array connector for the devices that support digital isolated input. ${ioHintSuffix}`,
        analog_input: `Analog input value of the device. Associated pins are located in pin array connector for the devices that support analog input. ${ioHintSuffix}`,
        open_collector_output: `Galvanically isolated open collector output state. Associated pins are located in pin array connector for the devices that support isolated OC output. ${ioHintSuffix}`,
        relay_output: `Digital non-latching relay output state. This type of relay will not remain in the last position when it was last powered and will go back to its default state. Associated pins are located in pin array connector for the devices that support relay output. ${ioHintSuffix}`,
        relay_output_cfg: 'Default relay state. If applicable after each device power up cycle the state of the relay will be set to a level shown here. This value can be changed at device’s WebUI section ‘Input/Output’, which can be found under ‘Services’.',
        oc_output_cfg: 'Default open collector output state. If applicable after each device power up cycle the state of the output will be set to a level shown here. This value can be changed at device’s WebUI section ‘Input/Output’, which can be found under ‘Services’.',
        digital_inout0: `Digital programmable Input / Output state. This pin can be set to open collector (OC) output or digital input. Associated pin is located in pin array connector for the devices that support configurable I/O. ${ioHintSuffix}`,
        digital_inout1: `Digital programmable Input / Output state. This pin can be set to open collector (OC) output or digital input. Associated pin is located in pin array connector for the devices that support configurable I/O. ${ioHintSuffix}`,
        digital_inout2: `Digital programmable Input / Output state. This pin can be set to open collector (OC) output or digital input. Associated pin is located in pin array connector for the devices that support configurable I/O. ${ioHintSuffix}`,
        dry_wet_input0: `Digital programmable Dry / Wet input pin. This pin can be set to ‘Passive (Dry) input’ or ‘Active (Wet) input’. Associated pin is located in pin array connector for the devices that support Dry / Wet input. ${ioHintSuffix}`,
        dry_wet_input1: `Digital programmable Dry / Wet input pin. This pin can be set to ‘Passive (Dry) input’ or ‘Active (Wet) input’. Associated pin is located in pin array connector for the devices that support Dry / Wet input. ${ioHintSuffix}`,
        relay_output_2: `Latching relay output state. This type of relay will remain in the last position when it was last powered. Associated pins are located in pin array connector for the devices that support relay output. ${ioHintSuffix}`,
        digital_non_isolated_input_4pin: `Digital non-isolated input state. Depending on the device model this pin is located in 4 pin power socket connector. This pin is treated as 'Configurable I/O 1' if device has programmable power socket pins. ${ioHintSuffix}`,
        digital_open_collector_output_4pin: `Digital open collector output state. Depending on the device model this pin is located in 4 pin power socket connector. This pin is treated as 'Configurable I/O 2' if device has programmable power socket pins. ${ioHintSuffix}`,
        analog_current_loop: `Analog current loop value of the device. Associated pin is located in pin array connector for the devices that support analog current loop. ${ioHintSuffix}`
    },  
    prompt: {
        'Unregister device(s)': {   
            subtitle: `Once you unregister a device, it will no longer be associated with your company and deleted from your account. A device can be re-added to ${appName}.`
        },
        'Unregister device': {
            subtitle: `Once you unregister a device, it will no longer be associated with your company and deleted from your account. A device can be re-added to ${appName}.`
        },
        'Reboot': {
            subtitle:`All selected online devices will be rebooted and unreachable for 1-2 minutes. Once rebooted, all selected devices will reconnect to ${appName} automatically.`
        },
        'Remove tag': {
            title: 'Remove tag?',
            subtitle: 'Are you sure you want to delete the selected tag(-s)? Once deleted, the tag(-s) will be removed from all assigned devices.'
        },
        'Remove user': {
            title: 'Remove user?',
            subtitle: 'Are you sure you wish to delete the selected user(-s)? You will not be able to access the deleted account(-s), so make sure you do not lock yourself out of accessing your devices.'
        },
        'Reset device(s)': {
            title: 'Reset device(s)?',
            subtitle: `Resetting a device completely removes it from ${appName}. All logs, device history and previously tracked metrics will be deleted. The next time the device connects to ${appName}, it will be treated like a brand new device.`
        },
        'Reset device': {
            title: 'Reset device?',
            subtitle: `Resetting a device completely removes it from ${appName}. All logs, device history and previously tracked metrics will be deleted. The next time the device connects to ${appName}, it will be treated like a brand new device.`
        },
        'Backup': {
            subtitle: "Are you sure you wish to generate and download the backup configuration(-s) of the selected device(-s)? You will be able to access and redownload the generated backup configuration(-s) anytime in Management → Files or in each device's details."
        },
        'Event logs': {
            subtitle: "Are you sure you wish to generate and download the event log(-s) of the selected device(-s)? You will be able to access and redownload the generated event log(-s) anytime in each device's details."
        },
        'Troubleshoot': {
            subtitle: "Are you sure you wish to generate and download the troubleshoot file(-s) of the selected device(-s)? You will be able to access and redownload the generated troubleshoot file(-s) anytime in each device's details."
        },
        'Remove report': {
            title: 'Remove report?',
            subtitle: "Are you sure you wish to remove the selected report template(-s)? All generated reports by the selected template(-s) will also be deleted."
        },
        'Remove pool': {
            subtitle: "Are you sure you wish to remove the selected credit pool(-s)? Only remove credit pools if you are sure they were added by accident or they need to be removed for some other reason."
        },
        'Turn on service': {
            subtitle: 'Are you sure you wish to enable service for the selected device(-s)? Enabling service will use up a credit once a device attempts to connect to the monitoring system. Once you enable service and the device(-s) in question connect(s), you will not be able to disable service, unless Auto Extend is disabled 30 days after the connection.'
        },
        'Turn off service': {
            subtitle: 'Are you sure you wish to disable service for the selected device(-s)? You can only disable service for devices that are not activated and currently do not have an active credit.'
        },
        'Turn on monitoring': {
            subtitle: 'Are you sure you wish to enable monitoring for the selected device(-s)? After enabling monitoring, it may take a few minutes for a device to become online again.'
        },
        'Turn off monitoring': {
            subtitle: 'Are you sure you wish to disable monitoring for the selected device(-s)? When monitoring is disabled, a device will appear offline and will not be monitored. This can be used to save data, as a device with disabled monitoring will not send data until monitoring is re-enabled.'
        },
        'Turn on auto extend': {
            subtitle: `Are you sure you wish to enable Auto extend for the selected device(-s)? If enabled, a device will automatically use a credit every 30 days. Keep in mind, even if 'Monitoring' is disabled, a credit will still be used if 'Auto Extend' is enabled.`
        },
        'Turn off auto extend': {
            subtitle: `Are you sure you wish to disable Auto extend for the selected device(-s)?. When disabled, after a credit becomes invalid, the device in question will become offline until a credit is manually assigned using the 'Service' switch.`
        },
        'Remove file': {
            title: 'Remove file?',
            subtitle: 'Selected file(s) will be removed.'
        },
        'Remove company': {
            title: 'Remove company?',
            subtitle: "Selected companies will be removed."
        },
        'Modal smtp remove': {
            title: 'Remove smtp?',
            subtitle: 'Are you sure you wish to delete this SMTP configuration? Alerts that use the deleted SMTP configuration will no longer function.'
        },
        'Modal deviceAlerts remove': {
            title: 'Remove device alert?',
            subtitle:'Are you sure you wish to delete this alert?'
        },
        'Set company as parent': {
            subtitle: 'Here you can unlink a subsidiary company from a parent company. After the action is complete selected subsidiary company will no longer be managed by the parent company and will become independent.'
        }
    },
    promptSuccess: {
        'Modal deviceAlerts remove': {
            subtitle: 'Alert removed successfully'
        },
        'Modal tags remove': {
            subtitle: 'Tag successfully removed'
        },
        'Modal smtp remove': {
            subtitle: 'Configuration removed successfully'
        }
    },
    promptFailed: {
        'Modal smtp remove': {
            subtitle: 'SMTP configurations cannot be removed if they are being used for any of the alerts, please make sure that configuration being removed is not in use. Are you sure you wish to delete this SMTP configuration?'
        }
    },
    addNewDevice: {
        company: defaultsHints.company,
        device_type: defaultsHints.device_type,
        auto_licence_enable: defaultsHints.auto_licence_enable,
        multi_add_devices: 'The name, serial and MAC address (must start with 00:1E:42 or 20:97:27) of the added device. The serial number must be 8 OR 10 numbers long.'
    },
    addDeviceList: {
        file_type: "What type of file you wish to add your device list from.",
        company: defaultsHints.company,
        file_csv: defaultsHints.file_csv,
        first_parameter: defaultsHints.first_parameter,
        mac_separator: defaultsHints.mac_separator,
        separation_symbol: defaultsHints.separation_symbol,
        auto_licence_enable: defaultsHints.auto_licence_enable,
        device_series: defaultsHints.device_type

    },
    addLocationList: {
        file_csv: defaultsHints.file_csv,
        first_parameter: defaultsHints.first_parameter
    },
    deviceListTags: {
        file_csv: defaultsHints.file_csv,
        first_parameter: defaultsHints.first_parameter,
        device_type: defaultsHints.device_type,
        tag: "Select which tags will be assigned."
    },
    manageTags: {
        action: 'Select what action will be performed.',
        select_tags: 'Select what tags you wish to perform the selected action to.'
    },
    changeDeviceListNames: {
        device_type: defaultsHints.device_type,
        file_csv: defaultsHints.file_csv,
        separation_symbol: defaultsHints.separation_symbol,
        first_parameter: defaultsHints.first_parameter
    },
    monitoringPeriod: {
        dynamic_monitoring: 'Dynamic monitoring updates these parameters: SIM State, PIN State, NET State, Signal, Operator, Operator Number, Connection State, Connection Type, RX Count(T/Y), TX Count(Y/Y), Firmware Version, SIM Slot, Router Uptime, Connection Uptime, Mobile IP, Cell ID, MCC, MNC, LAC, RX Day, RX Week ,RX Month, TX Day, TX Week, TX Month, ICCID, WAN State, WAN IP, Temperature',
        static_monitoring: 'Static monitoring updates these parameters: IMEI, Model, Manufacturer, Revision, IMSI, Product Code, Batch Number, Hardware Revision',
        hotspot_monitoring: 'Hotspot monitoring updates these parameters: Hotspot ID, Hotspot SSID, Hotspot Enabled, IP, Download, Upload, Download Limit, Upload Limit, Hotspot Users, Hotspot Users Pass, Hotspot User Active, Hotspot User Macs, Hotspot User Ips, Hotspot User Start Times, Hotspot User Use Times, Hotspot User Downloads, Hotspot User Uploads',
        io_monitoring: 'I/O monitoring updates these parameters: Digital Input, Digital Isolated Input, Analog Input, Digital OC Output, Digital Relay Output, Relay CFG, Output CFG',
        gps_monitoring: 'GPS monitoring updates these parameters: Latitude, Longitude, FIX, Altitude, Speed, Satellites, Course, Status, Enabled, Accuracy',
        speed_test: 'Speedtest monitoring updates these parameters: ',
        wireless_monitoring: 'Wireless monitoring updates these parameters: Access point status, SSID, sent/received bytes per session, client count, connected device name, connected device MAC address, connected device IP address.',
        ddns_monitoring: "DDNS monitoring updates these parameters: Number of DDNS rules, status of DDNS rules, hostname and IP address of DDNS rules, Time of the last and next update."
    },
    uploadToDevice: {
        device_configuration: 'Select the backup configuration you wish to upload to your device.',
        update_firmware: 'Select the firmware you wish to install to your {model_name} device.',
        select_offline: 'If this is checked, devices that are currently offline will be updated when they become online',
        keep_settings: 'If checked, all settings and configurations will be kept after the firmware update. If you are updating the firmware to an older version, all settings and configurations will be erased.'
    },
    passwordManager: {
        password: defaultsHints.password,
        password_length: "The length of the randomly generated password.",
        special_symbols: "If checked, the randomly generated password will contain special symbols: ~ ! @ # $ % ^ & * ( ) _ - + = { } [ ] | / : ; < > . ?",
        generate_summary: "If checked, password(s) will be shown in automatically generated CSV summary file. It can only be downloaded once in the 'Generated passwords' tab."
    },
    createAlert: {
        alert_event: 'The type of alert you wish to create.',
        event_subtype: 'Type of event that needs to happen for the alert to be triggered. Depends on Alert event.',
        alert_action: 'What kind of action you would like to happen when the alert requirements are met.',
        alert_subject: 'The subject of the sent email.',
        alert_message: `The body of the sent email. Time stamp - %ts Router name - %rn
        // Serial number - %sn WAN MAC address - %wm
        // LAN MAC address - %lm Current FW version - %fc
        // Connection state - %cs Operator name - %on
        // Connection type - %ct Signal strength - %ss
        // SIM slot in use - %su IMSI - %im
        // Event type - %et Event text - %ex
        // FW available on server - %fs LAN IP - %li
        // Network state - %ns WAN IP address - %wi
        // New line - %nl`,
        redundancy_enable: 'When enabled, emails will only be sent in specified intervals. This is done so as to not cause spam.',
        receiving_email: 'The email address you wish to send the emails to.',
        alert_smtp_email: 'The SMTP configuration that you wish to use when sending alert emails. SMTP configurations can be created/edited in your Profile settings.',
        recipient_phone_number: 'Phone number you wish to send an SMS alert to.',
        longitude: "East-west position of a point that when combined with the latitude value will act as the center of the geofence area.",
        latitude: "North-south position of a point that when combined with the latitude value will act as the center of the geofence area.",
        radius: "Radius (in meters) of the geofence area."
    },
    newTag: {
        company: 'Company that the created tag will belong to. Tags have to be created for a certain company, though companies can use tags created by their subsidiary companies.',
        name: 'Name of the created tag.',
        description: 'Description of the created tag. Used only for management purposes.'
    },
    newFtp: {
        server_ip: 'The IP address of your FTP server',
        server_port: 'The port of your FTP server',
        username: 'Username used to access your FTP server',
        password: defaultsHints.password,
        path: 'The path which your CSV file will be sent to.',
        enable: 'If checked, upon creation, this configuration will be enabled immediately'
    },
    newUser: {
        company: 'Which company the invited user will belong to.',
        email: 'Which email address the invitation will be sent to',
        role: "What role the invited user will have"
    },
    newCompany: {
        name: 'Name of the company you wish to create.',
        parent: 'Which parent company the newly created company will belong to.'
    },
    addNewReport: {
        name: 'Name of the report you wish to generate.',
        description: 'Description of the report you wish to generate.',
        period: "Time period for which the report applies to. If 'Report type' is 'Single (one time)' the generated report will be of the last day, week or month. If 'Report type' is 'Periodic', the periodically generated reports will generate every day, week or month.",
        apply_for: 'Select if this report applies to specific devices, companies or tags.'
    },
    activateCode: {
        credit_code: 'Type the credit code you wish to activate here.'
    },
    newCode: {
        company: 'Select which company the credits will be taken from.',
        credit_amount: 'Specify the number of credits you want to assign to the new code.'
    },
    movePools: {
        move_from_company: 'The company which the credits will be moved from.',
        move_to_company: 'The company which the credits will be moved to.'
    },
    AddRemotetelnet: {
        name: 'Name of the remote access rule. Only matters for management purposes.',
        destination_ip: `The IP address of the device you are trying to connect to - specifically, the IP address your ${appName} device uses to reach the destination device. Can be the IP address of your ${appName} device, in which case you will access the Telnet terminal of your ${appName} device.`,
        destination_port: 'The port that will be used to remotely connect to the device CLI. By default, Telnet uses port 23.',
        protocol: 'The protocol that will be used to remotely connect to your device CLI.',
        device: 'Select the device(-s) that will be used to remotely connect to the command line interfaces of your LAN devices. The destination devices have to be reachable from these selected RMS devices using the previously inserted IP address. If multiple devices are selected, multiple rules will be created with identical IP addresses but different devices.',
    },
    AddRemotesftp: {
        name: 'Name of the remote access rule. Only matters for management purposes.',
        destination_ip: `The IP address of the SFTP server you are trying to connect to - specifically, the IP address your ${appName} device uses to reach device with SFTP server.`,
        destination_port: 'The port that will be used to remotely connect to SFTP server. By default, SFTP protocol uses port 22.',
        protocol: 'The protocol that will be used to remotely connect to your SFTP server.',
        device: 'Select the device(-s) that will be used to remotely connect to SFTP server. The destination servers have to be reachable from these devices using the previously inserted IP address. If multiple devices are selected, multiple rules will be created with identical IP addresses but different devices.'
    },
    AddRemotedesktop: {
        name: 'Name of the remote access rule. Only matters for management purposes.',
        destination_ip: `The IP address of the PC you are trying to connect to - specifically, the IP address your ${appName} device uses to reach your PC.`,
        destination_port: 'The port that will be used to remotely connect to your PC. By default, RDP protocol uses port 3389, VNC protocol uses port 5900.',
        protocol: 'The protocol that will be used to remotely connect to your device. RDP lets you connect to Windows PCs (VNC - to Linux PCs) without any 3rd party clients, all that is needed is to configure your PC to allow remote connections.',
        device: 'Select the device(-s) that will be used to remotely connect to your PC. The destination PCs have to be reachable from these devices using the previously inserted IP address. If multiple devices are selected, multiple rules will be created with identical IP addresses but different devices.',
        credentials_required: 'If enabled, you will be prompted to insert your PC username and password (or if you are using VNC - just the password) before attempting a connection. If disabled, you will be prompted to insert the same information once connected.'
    },
    AddRemotessh: {
        name: 'Name of the remote access rule. Only matters for management purposes.',
        destination_ip: `The IP address of the device you are trying to connect to - specifically, the IP address your ${appName} device uses to reach the destination device. Can be the IP address of your ${appName} device, in which case you will access the SSH of your ${appName} device.`,
        destination_port: 'The port that will be used to remotely connect the device CLI. By default, SSH uses port 22.',
        protocol: 'The protocol that will be used to remotely connect to your device CLI.',
        device: `Select the device(-s) that will be used to remotely connect to the command line interfaces of your LAN devices. The destination devices have to be reachable from these selected ${appName} devices using the previously inserted IP address. If multiple devices are selected, multiple rules will be created with identical IP addresses but different devices.`
    },
    AddRemotehttp: {
        name: 'Name of the remote access rule. Only matters for management purposes.',
        destination_ip: `The IP address of the device you are trying to connect to - specifically, the IP address your ${appName} device uses to reach the destination device.`,
        destination_port: 'The port that will be used to remotely connect to your device web interface. By default, HTTP uses port 80, HTTPS uses port 443.',
        protocol: 'The protocol that will be used to remotely connect to your device web interface.',
        device: `Select the device(-s) that will be used to remotely connect to the web interfaces of your LAN devices. The destination device has to be reachable from this selected ${appName} device using the previously inserted IP address. If multiple devices are selected, multiple rules will be created with identical IP addresses but different devices.`,
        rms_access: 'If enabled, the destination device will only be remotely accessible if you have an active session with this account (you may be prompted to login if you are currently not logged in to this account). Otherwise, the device will be remotely accessible from anywhere using the generated links.'
    },
    newPool: {
        credit_type: "Select what kind of credits you wish to assign. ",
        credit_count: "How any credits you wish to assign.",
        company: "Select which company you wish to assign the credits to."
    },
    SMTPCreate: {
        host: 'Commonly used emails: Gmail - smtp.gmail.com; Yahoo - smtp.mail.yahoo.com; Hotmail - smtp.live.com',
        port: 'The most commonly used SMTP ports are 25, 465, 587 and 2525. For example, Gmail uses port 465 for SSL connections and port 587 for TLS connections.'
    },
    vpnConfig: {
        configuration_type: 'Type of configuration to create. Server or Client',
        configuration_name: 'Name of configuration to be created',
        dev_type: 'Virtual VPN interface type',
        protocol: 'A transport protocol used for connection',
        port: 'Port for both local and remote endpoint. Make sure that this port is open in firewall',
        lzo: 'Use fast LZO compression. With LZO compression your VPN connection will generate less network traffic',
        authentication: 'Authentication mode used to secure data session',
        encryption: 'Packet encryption algorithm',
        tls_cipher: 'Packet encryption algorithm cipher',
        allowed_tls_ciphers: 'Array of custom tls ciphers',
        remote_host_address: 'Remote host/IP address, IP address or domain name of OpenVPN server',
        resolve_retry: 'Try to resolve server hostname for x seconds before giving up',
        keep_alive: 'Try to keep a connection alive. Two values are required: ping_interval and ping_restart, e.g. 10 120',
        client_to_client: 'Allow client-to-client traffic',
        local_tunnel_ip: 'IP address of virtual local network interface',
        remote_tunnel_ip: 'IP address of virtual remote network interface',
        remote_network_ip: 'Remote network IP address',
        remote_network_netmask: 'Subnet mask of remote LAN network',
        remote_network_ipv6: 'Remote network IPv6 address',
        virtual_network_ip: 'IP address used for virtual network',
        virtual_network_netmask: 'Subnet mask used for virtual network',
        virtual_network_ipv6: 'IPv6 address used for virtual network',
        push_options: 'Array of configuration options to push back to the client for remote execution. ex route 192.168.1.0 255.255.255.0',
        allow_duplicate_certs: 'All clients can have same certificates',
        hmac_authentication_algorithm: 'HMAC authentication algorithm',
        additional_hmac: 'Additional HMAC authentication, Add an additional layer of HMAC authentication on top of the TLS control channel to protect against DoS attacks',
        hmac_authentication_key: 'HMAC authentication key. Aws_file location and file md5 must be provided',
        hmac_key: 'HMAC authentication key. Aws_file location and file md5 must be provided',
        hmac_key_direction: 'HMAC authentication key direction value is arbitrary and must be opposite between communicating parties (or omitted entirely)',
        username: 'VPN client username',
        password: 'VPN client password',
        extra_options: 'Enter any additional options to be added to the OpenVPN configuration',
        certificate_authority: 'Certificate authority, The digital certificate verifies the ownership of a public key by the named subject of the certificate. Aws_file location and file md5 must be provided',
        server_certificate: 'Server certificate, Identify a server or a user, authenticating the server to the server and establishing precisely who they are. Aws_file location and file md5 must be provided',
        server_key: 'Server key, It has been generated for the same purpose as server certificate. Aws_file location and file md5 must be provided',
        private_key_decryption_password: 'Decrypt private key with password',
        static_pre_shared_key: 'Pre-shared key (PSK) is a shared secret which was previously shared between the two parties using some secure channel before it needs to be used. Aws_file location and file md5 must be provided',
        username_password: 'File containing usernames and passwords againts which the server can authentiate clients. Each username and password pair should be placed on a single line and separated by a space. Aws_file location and file md5 must be provided',
        dh_key_exchange: 'Diffie-Hellman key exchange. Remote path, aws_file location and file md5 must be provided',
        crl: 'Revoking a certificate means to invalidate a previously signed certificate so that it can no longer be used for authentication purposes. Upload a .pem revocation file. Aws_file location and file md5 must be provided'
    },
    'management.devices': {
        updated_at: "Last time any information about the device was modified. This includes the device's status (Online / Offline)."
    },

    addVPN: {
        name: 'Name of the VPN hub. This will be used in the authentication process when attempting to connect a VPN client.',
        description: 'Description of the VPN hub. Only used for management purposes.',
        tag: 'You can assign any created tags to the VPN hub. Only used for management purposes.'
    },
    vpnConfiguration: {
        mac: 'The virtual MAC address of the VPN hub.',
        ip_address: 'The VPN hub’s IP address, which, once connected, can be used to reach the VPN hub itself.',
        subnet_mask: 'The subnet mask used to determine which part of the network can be accessed using the VPN.',
        ip_address_range: 'The IP range from which each client’s IP address will be assigned.',
        lease_limit: 'How long an IP will be leased from the formerly specified range. Leased out addresses will expire after the amount of time specified in this field and the device that was using the lease will have to request a new DHCP lease.'
    },
    vpnAddRoutes: {
        clients: 'Select the client which you want to push this route to. This can be any VPN client you have created in this hub (RMS device, RMS user, custom user).'
    },
    vpnSetStaticIp:{
        ip_address: `The IP address has to be taken from /30 subnets and will be automatically adjusted in order to be compatible with Windows clients and the TAP-Windows driver. Specifically, the last octet in the IP address of each endpoint pair must be taken from the following set:
        [  1,  2] [  5,  6] [  9, 10] [ 13, 14] [ 17, 18]
        [ 21, 22] [ 25, 26] [ 29, 30] [ 33, 34] [ 37, 38]
        [ 41, 42] [ 45, 46] [ 49, 50] [ 53, 54] [ 57, 58]
        [ 61, 62] [ 65, 66] [ 69, 70] [ 73, 74] [ 77, 78]
        [ 81, 82] [ 85, 86] [ 89, 90] [ 93, 94] [ 97, 98]
        [101,102] [105,106] [109,110] [113,114] [117,118]
        [121,122] [125,126] [129,130] [133,134] [137,138]
        [141,142] [145,146] [149,150] [153,154] [157,158]
        [161,162] [165,166] [169,170] [173,174] [177,178]
        [181,182] [185,186] [189,190] [193,194] [197,198]
        [201,202] [205,206] [209,210] [213,214] [217,218]
        [221,222] [225,226] [229,230] [233,234] [237,238]
        [241,242] [245,246] [249,250] [253,254]
        `
    }
};

export const links = {
    addNewDevice: { title: 'How to add a new device to RMS', url: 'https://www.youtube.com/watch?v=cFYt_y15czo&t=1m20s&autoplay=1' },
    deviceListTags: { title: 'How to assign tags to device list?', url: 'https://wiki.teltonika-networks.com/view/RMS_-_Device_menu#Assign_tags_to_device_list' },
    passwordManager: { title: 'How to generate new password(s) for device(s)?', url: 'https://wiki.teltonika-networks.com/view/How_to_change_password(s)_for_device(s)_in_RMS%3F' }
}

export const descriptions = {
    newTag: {
        description: `Tags can be used to easily differentiate your various devices. They can also be used to install firmware to devices that have specific tags assigned.`
    },
    newCompany: {
        description: "Here you can create a subsidiary company that will belong to your current company. Keep in mind, if you are a 3rd level company (your parent company has a parent company of its own), you cannot create a subsidiary company of your own."
    },
    removeTag: {
        description: "Are you sure you want to delete the selected tag(-s)? Once deleted, the tag(-s) will be removed from all assigned devices."
    },
    newUser: {
        description: `If you wish to invite a user to join you can do so here. Invited users will receive an email inviting them to join. Should they accept the invitation and register, they will automatically be assigned to the company and role you specify in these fields.`
    },
    movePools: {
        description: `Here you can move any number of available credits from one company to another.`
    },
    newFtp: {
        description: `This tab is used to create an FTP server configuration which ${appName} will use to send your device’s information to the desired FTP server. Information sent will be sent in CSV (Comma separated values) format.`
    },
    addNewDevice: {
        description: `This form is used to add a device or multiple devices to your {company_name} company. To successfully add a device, you must use your device’s serial number and MAC address (or IMEI if you are adding a TRB device), both of which can be found on the box the device came in, as well as in your router web settings.`,
        link: links.addNewDevice
    },
    addDeviceList: {
        description: `This form is used to add multiple devices at once using either a personally composed CSV file or a production CSV file which is provided by the device manufacturer. If you have a production CSV file, you can use it to add the list of devices to ${appName} all at once. Keep in mind, adding more than a few hundred devices at once will take some time.`
    },
    addLocationList: {
        description: `This tab is used to assign static locations to a list of devices using a CSV file. CSV (Comma Separated Values) is a simple file format used to store tabular data. This file must contain a serial number / MAC address, longitude and latitude, all separated by a separation symbol. Each device must be in a separate line.`
    },
    deviceListTags: {
        description: `This tab is used to assign device tag(s) for multiple devices using a CSV file. CSV (Comma Separated Values) is a simple file format used to store tabular data. This file must contain a serial number or a MAC address, both separated by a separation symbol (,).`,
        link: links.deviceListTags
    },
    manageTags: {
        description: `Tags can be used to easily differentiate and filter your various devices. Here you can select a combination of an action and tags to be applied to selected devices.`
    },
    changeDeviceListNames: {
        description: `CSV (Comma Separated Values) is a simple file format used to store tabular data. This file must contain a serial number/MAC address/IMEI, a name, and description.`
    },
    monitoringPeriod: {
        description: `Here you can set the period for when information should be updated for the selected device(s). If monitoring is switched off and provided value is greater than zero - monitoring will be turned off, if the value is zero - changes will not be made and existing configuration will be kept.`
    },
    cancelPendingUpdates: {
        description: `Using this feature you can cancel any pending updates that are set on any device, whether they’re backup configurations or firmwares. If the same device has a pending update for both firmware and backup the firmware will be initiated first.`
    },
    deviceAccess: {
        description: `This function provides you with the possibility to set up remote HTTP access to devices that are connected to a device's LAN, provided that the device in question has been registered to {company_name}. `
    },
    passwordManager: {
        description: `This menu is used to generate random passwords of a certain length for selected devices. Once you click "Generate", all selected devices will have their passwords changed to random ones of a given length. All generated passwords can then be downloaded in one convenient CSV file where all devices and their new passwords are listed.`,
        link: links.passwordManager
    },
    passwordManager_set: {
        description: `In this tab, you can set the password of the selected device(s). Keep in mind, the password must be at least 8 characters long, must include one uppercase and lowercase letter and one digit.`,
    },
    passwordManager_csv: {
        description: `In this tab, you can change the passwords of multiple devices at once using a CSV file. The file must consist of serial numbers and wanted passwords, separated in different lines for each device. Keep in mind, the password must be at least 8 characters long, must include one uppercase and lowercase letter and one digit. You can download a demonstration file below.`,
    },
    passwordManager_table: {
        description: `Here you can download a CSV file containing the randomly generated password(-s) generated in the 'Generate password' tab. As it is also stated below, generated passwords can only be downloaded once. If you lose your generated password and can no longer download it, you can re-generate a new password again.`,
    },
    createAlert: {
        description: `This menu is used to create special alerts for your selected registered devices. You can configure these alerts to send emails whenever the alert events are triggered. If you wish to send alert emails from your own email address, you must setup an SMTP configuration in Alerts → Email SMTP.`
    },
    deviceAlerts: {
        description: `This is where all of your device alerts are listed.`
    },
    addNewFile: {
        description: `This is where device files are uploaded. For management purposes, you can also set a description for the uploaded file, but this is only optional. Maximum file size is 80MB.`
    },
    AddRemotetelnet: {
        description: `Using ${appName} Connect - Remote Telnet, you can remotely connect to the CLI (Command line interface) of any device that supports Telnet and is connected to your ${appName} device's LAN.`
    },
    AddRemotesftp: {
        description: `Using ${appName} Connect - Remote SFTP you can remotely connect to any device that has SFTP (SSH File Transfer Protocol, also known as Secure FTP) server set up and is connected to your ${appName} device's LAN.`
    },
    AddRemotedesktop: {
        description: `Using ${appName} Connect - Remote Desktop, you can remotely connect to a PC that supports remote desktop functionality (RDP or VNC protocols).`
    },
    AddRemotessh: {
        description: `Using ${appName} Connect - Remote SSH, you can remotely connect to the CLI (Command line interface) of any device that supports SSH and is connected to your ${appName} device's LAN.`
    },
    AddRemotehttp: {
        description: `Using ${appName} Connect - Remote HTTP/HTTPS, you can set up remote HTTP or HTTPS access to devices that are connected to an ${appName} device's LAN. This functionality can be used to conveniently access a device's web interface remotely. The LAN devices do not need to be connected to ${appName}.`
    },
    authPairing: {
        description: `Using the Teltonika ID app you can set up your account so as to only login once you verify that it is you by inputting a set verification code or by using biometrics.`
    },
    changeMfa: {
        description: `If selected, this company's users will have to go through extra steps to successfully login. For example, if 'Email' is selected, every time anyone logs into any account belonging to this company, an email containing a verification code will be sent to each account's email address. The sent verification code will then have to be inserted into the login screen to successfully login. This feature can be enabled for extra security.`
    },
    deviceCLI: {
        description: `This is a generated CLI frame which can be used to access your device's CLI directly through {company_name}.`
    },
    deviceWebui: {
        description: `Here you can set a duration of how long the WebUI link will be active. After the designated time is up, a new link will have to be generated to continue using the device WebUI.`
    },
    SMTPConfig: {
        description: `SMTP stands for Simple Mail Transfer Protocol. Most e-mail systems that send mail over the Internet use SMTP to send messages from one server to another. These settings are used when creating device alerts, to allow the user to receive alert emails.`
    },
    viewUpdateHistory: {
        description: `Here you can view this device's information each time it was updated either manually or automatically.`
    },
    firmwareUpload: {
        description: `This window is used to install a firmware to your selected devices. The firmwares listed are all uploaded in the {router-link} section. Once firmware installation begins, the device(-s) will not be reachable for 1-2 minutes.`
    },
    configurationUpload: {
        description: 'This window is used to upload backup configurations to your selected devices. The configurations listed are all uploaded in the {router-link} section. Once the upload begins, the device(-s) will not be reachable for 2-3 minutes.'
    },
    editTag: {
        description: 'Tags can be used to easily differentiate your various devices. They can also be used to install firmware to devices that have specific tags assigned.'
    },
    addNewReport: {
        description: "Here you can create report templates that can be used to generate device reports for a selected time period. Reports can be set to be single, manually generated reports, or automatic periodically generated ones. Single reports can be set for any custom time period, while periodic reports can only be for the last day, week or month. You can also view all your generated reports from each report template. Any periodic report template can be disabled if you don't wish to keep automatically generating any specific periodic reports."
    },
    newPool:{
        description: "Here you can create a new credit pool for a certain company. However many credits you assign here will create a single credit pool, that can then be used by the assigned company. Free credits are mainly used for testing purposes or if we wish to gift a user some amount of credits. All credits that were paid for fall under the 'Paid' category."
    },
    activateCode: {
        description: "Here you can activate any created credit code. Once a code is activated, its status will change to Used. Credit codes can be used by anyone, regardless of company."
    },
    companyPrompt: {
        description: "To proceed you must create a name for your company. Once you create a company you can invite different users to it. You can also create different subsidiary companies to further manage your device fleets."
    },
    addVpn: {
        description: "This menu is used to create a new VPN hub. You will be able to further customize your VPN hub after creation. The description and tags are only used for management purposes."
    },
    vpnHubStaticIp: {
        description: 'Here you can set a static IP for your clients instead of them getting an IP address via DHCP. If online, the VPN server will be automatically restarted upon confirming the changes.'
    },
    vpnConfigurationChange: {
        description: `Using this menu you can change the VPN hub's IP address and subnet mask. If online, the VPN server will be automatically restarted upon confirming the changes.`
    },
    vpnDHCPCreate: {
        description: `In this menu you can push extra DHCP options such as DNS and WINS server addresses to VPN clients. Once created, it is necessary to restart the VPN hub to push all created options to all VPN clients in the hub.`
    }
}

export function getHint(name, type) {
    let key = name?.includes('_2') ? name?.replaceAll("_2", "") : name;
    if (type) {
        if (hints[type]?.[key])
            return hints[type][key];
        return null;
    }
    else {
        if (key && hints['fields'][key])
            return hints['fields'][key];
        return null;
    }
}

export function getDescription(form) {
    if (form) {
        return descriptions[form];
    }
    return null;
}
