/* webpackMode: "eager" */
import axios from 'axios'
import sessionService from '@/services/sessionService'
import { cacheAdapterEnhancer } from 'axios-extensions'; 
import {getCookie} from '@/utils';

// export let API_URL = `${process.env.VUE_APP_API_URL}`;
const REVIEW_ID = getCookie('api_review');
// if (process.env.NODE_ENV === 'review' || process.env.NODE_ENV === 'development'){
export const API_URL = REVIEW_ID ? `https://${REVIEW_ID}-api.review.demorms.teltonika-networks.com` : `${process.env.VUE_APP_API_URL}`;
// }

const remoteLocations = ['/rms-connect/ssh', '/rms-connect/telnet'];

const HTTP = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    // timeout: 5000,
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: true })
});

const pendingRequests = window.pendingRequests = {};

HTTP.defaults.xsrfCookieName = getCookie('RMS-XSRF-TOKEN')
    ? 'RMS-XSRF-TOKEN'
    : 'XSRF-TOKEN';

HTTP.defaults.xsrfHeaderName = 'X-XSRF-TOKEN';

HTTP.interceptors.request.use(req =>{
    if(req.method === 'get' && !req.skipPendingList){
        const cancelTokenSource = axios.CancelToken.source();
    
        req.cancelToken = cancelTokenSource.token;
        req.cancelSource = cancelTokenSource;
        if(pendingRequests[req.url] && !req.ignoreCancel) {
            pendingRequests[req.url].cancelSource.cancel('Canceled for new request with same URL');
        }
        pendingRequests[req.url] = req;
    }
    
    return req;
});

HTTP.interceptors.response.use(function (response) {
    delete pendingRequests[response.config.url];
    return response;
}, async function (error) {
    delete pendingRequests[error?.response?.config?.url];
    if (error?.response?.status === 401) {
        const url = error.response?.config?.url;
        if(url === '/user' && remoteLocations.includes(window.location.pathname)){
            return Promise.reject(error.response);
        }
        if (url && url.includes(process.env.VUE_APP_AUTH_URL)) {
            return Promise.reject(error.response);
        }
        if (error.response.data.errors[0]?.message === 'Unauthorized. (XSRF)'){
            return Promise.reject(error.response);
        }
        if(sessionService.isAuthenticated){
            await sessionService.refreshToken(error.config);
        }
    }
    return Promise.reject(error?.response || error);
    
});

export default HTTP;