import dayjs from 'dayjs';
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));


/** @module utils */

/**
 * @param {Array} selectionArray
 * @param {Object} data
 * @return {Object}
 * @memberof module:utils
 */

export function makeBulkJson(selectionArray, actionData, options){
    // const payload = options?.additionalData || {};
    const identifier = options?.identifier || 'id';
    const isDeviceArray = Object.prototype.hasOwnProperty.call(actionData, 'action');

    if(isDeviceArray){
        return {
            ...actionData,
            device_id: selectionArray.map(item => item[identifier])
        };
    }else{
        return {"data": selectionArray.map(item => {
                return {
                    device_id: item[identifier],
                    ...actionData
                }
            })
        };
    }

    
}

export function getCookie(name) {
    var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return v ? v[2] : undefined;
}

export function fileDeviceModel(model){
    const standaloneModels = ['RUT300', 'RUT360', 'RUT952']
    if(standaloneModels.includes(model)) return model;

    return model.slice(0,4);
}

export function mapMarkerIcon(status){
    const markers = ['marker-offline', 'marker-online', 'marker-pending'];

    if(markers[status]) return markers[status];
    return 'marker-unregistered';
}

export function urlBase64Decode (str) {
    var output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
        case 0: { break; }
        case 2: { output += '=='; break; }
        case 3: { output += '='; break; }
        default: {
            throw 'Illegal base64url string!';
        }
    }

    return window.decodeURIComponent(escape(window.atob(output)));
}

export const decodeToken = function(token){
    var parts = token.split('.');

    if (parts.length !== 3)  throw new Error('JWT must have 3 parts');

    var decoded = urlBase64Decode(parts[1]);
    if (!decoded) throw new Error('Cannot decode the token');
    
    return JSON.parse(decoded);
    
}

export const getTimezone = generateTimezone();

export const lazyLoadComponent = function(component){
    const AsyncHandler = () => ({
        component: component,
        // A component to use while the component is loading.
        loading: require("@/components/_Loader.vue").default,
        // Delay before showing the loading component.
        // Default: 200 (milliseconds).
        delay: 200,
        // A fallback component in case the timeout is exceeded
        // when loading the component.
        //error: () => import("@components/_timeout"),
        // Time before giving up trying to load the component.
        // Default: Infinity (milliseconds).
        timeout: 5000,
    });

    return Promise.resolve({
        functional: true,
        render(h, { data, children }) {
            // Transparently pass any props or children
            // to the view component.
            return h(AsyncHandler, data, children)
        },
    })
}

export function downloadBlob(res){
    const fileName = res.headers['content-disposition'].match(/filename="(.+)"/)[1];
    const link = document.createElement('a');
    const url = URL.createObjectURL(new Blob([res.data]));

    link.href = url;
    link.setAttribute('download', fileName);
    link.click();
    
    URL.revokeObjectURL(url);
}

export function fieldSorter(fields, firstToLast = true) {
    return function (a, b) {
        return fields
            .map(function (o) {
                
                var dir = 1;
                if (o[0] === '-') {
                    dir = -1;
                    o = o.substring(1);
                }

                let first = !isNaN(a[o]) ? Number(a[o]) : a[o];
                let second = !isNaN(b[o]) ? Number(b[o]) : b[o];
                
                if(firstToLast){
                    if (first > second) return dir;
                    if (first < second) return -(dir);
                }else{
                    if (first < second) return dir;
                    if (first > second) return -(dir);
                }
                return 0;
            })
            .reduce(function firstNonZeroValue(p, n) {
                return p ? p : n;
            }, 0);
    };
}

export const capitalize = (s) => {
    if (typeof s !== 'string') return '';
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function getDeviceThumbnail(model, isFacelift = false){
    if (!model) return `/assets/img/devices/unknown.png`;

    model = isFacelift
        ? `${model.toLowerCase()}-facelift`
        : model.toLowerCase();

    return `/assets/img/devices/${model}.png`;
}

export function deviceType(model){
    if(typeof model !== 'string') return 'router';
    if(model.toLowerCase()?.includes('trb')) return 'gateway';
    return 'router';
}

export function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const passwordRules = [
    {text: 'At least 8 characters', regex: /.{8,}/},
    {text: 'At least one number [0-9]', regex: /[0-9]+/},
    {text: 'At least one lowercase letter [a-z]', regex: /[a-z]+/},
    {text: 'At least one uppercase letter [A-Z]', regex: /[A-Z]+/},
    {text: 'At least one special symbol [#?!@$%^&*-]', regex: /[#?!@$%^&*-]+/}
];



export function copyToClipboard(string) {
    if(!string) return;

    let field = document.createElement("textarea");
    document.body.appendChild(field);
    field.value = string;
    field.select();
    document.execCommand('copy');
    document.body.removeChild(field);
}
export function getResponseMessage(res){
    if(!res) return;
    const isSuccess = res.data?.success;

    if(!res.config) return 'Server error';
    const verb = responseVerb(res.config?.method, res.config?.url);
    const item = responseItem(res.config?.url, res.config?.method) || 'item(s)';
    if(isSuccess){
        if(verb.slice(-1) === "t"){
            return `${capitalize(item)} ${verb} successfully`;
        }
        else if(verb.slice(-1) === "d"){
            return `${capitalize(item)} ${verb}ed successfully`;
        }
        else{
            return `${capitalize(item)} ${verb}d successfully`;
        }
    }else if(res.data?.errors){
        return res.data.errors[0].message;
    }
    return `Failed to ${verb} ${item} (${res.statusText})`;
}

function responseVerb(method, endpoint){
    if (enpointVerbs[endpoint]) return enpointVerbs[endpoint];
    switch (method) {
        case 'put': 
            return 'update';
        case 'delete':
            return 'remove';
        case 'post':
            return 'create';
        default: 
            return 'get';
    }
}

const enpointVerbs = {
    '/devices/passwords/generate': 'operation complete',
    '/devices/passwords/set': 'operation complete',
    '/files': 'upload',
    '/companies/data': 'add',
    '/users/invite': 'sent',
    '/devices/updates/cancel': 'operation complete',
    '/credits/codes/activate': 'operation complete',
    '/credits/move': 'operation complete'
}

const endpointKeys = {
    'generate': 'password',
    'access':'remote access',
    'csv': 'device(s)',
    'tokens': 'credit code'
    // 'tokens': 'credit code',
    // 'activate': 'credit code'
}

function responseItem(endpoint, method){
    if(endpoint.includes('/devices/tags')) return 'device(s) tags';
    const stringArray = endpoint.split("?")[0].match(/([a-zA-Z]+)/g);
    let item = stringArray[stringArray.length-1];
    if (endpointKeys[item]) return endpointKeys[item];
    if (item.slice(-3) === 'ies') return item.slice(0, -3)+'y'; 
    if (item.slice(-1) === 's') {
        if(method === 'delete'){
            return `${item.slice(0, -1)}(s)`; 
        }
        return item.slice(0, -1);
    }
    return item;
}

export function updateMappedNames(item, setter){
    if(!setter) return;
    
    const id = Object.prototype.hasOwnProperty.call(item, 'value')
        ? item.value
        : item

    const storageKey = `mapped_${setter}_names`;
    const mappedNames = JSON.parse(sessionStorage.getItem(storageKey)) || {};

    if(!item.text) return;

    mappedNames[id] = item.text;
    sessionStorage.setItem(storageKey, JSON.stringify(mappedNames)); 
}

export function getMappedName(id, setter){
    if(!setter) return;
    const storageKey = `mapped_${setter}_names`;
    const mappedNames = JSON.parse(sessionStorage.getItem(storageKey)) || {};
    return mappedNames[id];
}

export function passwordify(string){
    if(typeof string !== 'string') return;
    return '•'.repeat(string.length);
}

function generateTimezone(){
    const timezone = localStorage.getItem('setTimeZone') || dayjs.tz.guess();
    return {
        name: timezone,
        offset: dayjs().tz(timezone).utcOffset()
    }
}

export const ipPattern = "^(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}$";

export const telPattern = "^\\s*(?:\\+?(\\d{1,3}))?[- (]*(\\d{3})[- )]*(\\d{3})[- ]*(\\d{3,4})$";

export const domainPattern = `^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\\.)+[A-Za-z]{2,6}$`;

export const portNames = {
    '22': ['ssh', 'sftp'],
    '23': 'telnet',
    '80': 'http',
    '443': 'https',
    '3389': 'rdp',
    '5900': 'vnc'
}

const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export function generateString(length) {
    let result = '';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function parseFirmwareVersion(fw) {
    if(!fw) return;

    const verRegex = /((\d{2}\.){2}\d+)(\..+)?/;
    if(fw.match(verRegex)) return fw.match(verRegex)[1];
    return null;
}

export function truncateString(str, num) {
    if (str.length > num) {
        return str.slice(0, num) + "...";
    } else {
        return str;
    }
}