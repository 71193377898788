import Vue from 'vue'
import App from './App.vue'

import './registerServiceWorker'

import router from './router'
import httpService from './services/httpService'
import themeService from './services/themeService'
import sessionService from "@/services/sessionService";
import UserStorage from "@/services/userStorageService";

import Modals from '@/utils/plugins/modalPlugin'
import VueSnackbar from 'vue-snack'

import BaseIcon from '@/components/BaseIcon.vue';
import BaseAlert from '@/components/BaseAlert.vue';
import ErrorList from '@/components/ErrorList.vue';
import FormField from '@/components/FormField.vue';
import Loader from '@/components/_Loader.vue';
import CopyToClipboard from "@/components/CopyToClipboard.vue";
import VueSanitize from "vue-sanitize";

import {getResponseMessage, getTimezone} from '@/utils';

import tooltipDirective from '@/directives/tooltip';

Vue.prototype.$theme = themeService;

Vue.component('BaseIcon', BaseIcon);
Vue.component('BaseAlert', BaseAlert);
Vue.component('ErrorList', ErrorList);
Vue.component('FormField', FormField);
Vue.component('Loader', Loader);
Vue.component('CopyToClipboard', CopyToClipboard);

Vue.directive('tooltip', tooltipDirective);

Vue.directive('tooltip', tooltipDirective);

Vue.prototype.$http = httpService;
Vue.prototype.$eventHub = new Vue({});
Vue.prototype.$resMsg = getResponseMessage;
Vue.prototype.$timezone = getTimezone;
Vue.prototype.$isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
Vue.prototype.$maxDevices = 0;

Vue.use(VueSanitize, {
    ...VueSanitize.defaults,
    allowedTags: [],
    allowedAttributes: {},
    disallowedTagsMode: 'escape'
});

Vue.prototype.$updateDeviceValues = () => {
    return Vue.prototype.$http.get('/local/config', {forceUpdate: true})
        .then(res => {
            Vue.prototype.$maxDevices = window.maxDevices = res.data.data.max_devices_count;
            Vue.prototype.$totalDevices = window.totalDevices = res.data.data.total_devices_count;
        }).catch(()=> {});
}

Vue.use(VueSnackbar, { position: 'top', time: 5000, close: true });
Vue.use(Modals, router);

Vue.config.productionTip = false;

const app = new Vue({
    router,
    render: h => h(App)
});

window.hj = () => {};

function initApp(){
    checkMapKey();
    checkIsMFARequired();
    sessionService.initSession().then((data) => {
        if (!data) return;
        app.$mount('#app');    
        Vue.prototype.$userStorage = new UserStorage(Vue);   
    }).catch(async (error) => {
        if (error?.data?.errors?.[0]?.code === 'EMAIL_NOT_VERIFIED') {
            const AuthNotice = await import("@/views/AuthNotice.vue" /* webpackChunkName: "maintenance" */);
            new Vue({
                render: h => h(AuthNotice.default)
            }).$mount("#app");
        } else {
            maintenanceMode(error);
        }
    });
}

Vue.prototype.$initApp = () => initApp();
Vue.prototype.$initMaintenance = maintenanceMode;

async function maintenanceMode(error){
    const Maintenance = await import("@/views/Maintenance.vue" /* webpackChunkName: "maintenance" */);
    new Vue({
        render: h => h(Maintenance.default, { props: { data: error || {} } })
    }).$mount("#app");
}

async function rmsDesktopCompile(){
    const RmsDesktop = await import("@/views/DesktopConnect.vue" /* webpackChunkName: "RemoteConnect" */);
    new Vue({
        // router,
        render: h => h(RmsDesktop.default)
    }).$mount("#app");
}

async function rmsMobileCompile(){
    const RmsMobile = await import("@/components/modals/StreamModal.vue" /* webpackChunkName: "maintenance" */);
    new Vue({
        // router,
        render: h => h(RmsMobile.default)
    }).$mount("#app");  
}


async function rmsSFTPCompile(){
    const RmsSFTP = await import("@/views/SFTPConnect.vue" /* webpackChunkName: "maintenance" */);
    new Vue({
        // router,
        render: h => h(RmsSFTP.default)
    }).$mount("#app");  
}

async function rmsConsoleCompile(){
    const RmsTelnet = await import("@/views/ConsoleConnect.vue" /* webpackChunkName: "maintenance" */);
    new Vue({
        // router,
        render: h => h(RmsTelnet.default)
    }).$mount("#app");  
}

if(window.location.pathname.includes('rms-connect/desktop')){
    rmsDesktopCompile();
}
else if(window.location.pathname.includes('rms-connect/ssh') || window.location.pathname.includes('rms-connect/telnet')){
    rmsConsoleCompile();
}
else if(window.location.pathname.includes('rms-connect/mobile')){
    rmsMobileCompile();
}
else if(window.location.pathname.includes('rms-connect/sftp')){
    rmsSFTPCompile();
}
else{
    Vue.prototype.$initApp();
}

if(window.isSecureContext && navigator.serviceWorker){
    navigator.serviceWorker.getRegistrations().then((registrations) => {
        for(let registration of registrations) {
            registration.unregister();
        }
    });
}

window.requestIdleCallback = window.requestIdleCallback || function (cb) {
    var start = Date.now();
    return setTimeout(function () {
    cb({
        didTimeout: false,
        timeRemaining: function () {
        return Math.max(0, 50 - (Date.now() - start));
        }
    });
    }, 1);
}

function checkMapKey(){
    Vue.prototype.$mapsKey = null;
    fetch('/google_maps_key.txt', {
        headers:{
            'Accept': 'text/plain',
            'Cache-Control': 'no-store'
        }
    })
    .then(response => response.blob())
    .then(blob => {
        if(blob && blob.type !== 'text/plain') return;
        blob.text().then(key => { if(key) Vue.prototype.$mapsKey = key });
    });
}

function checkIsMFARequired(){
    Vue.prototype.$isMFARequired = true;
    fetch('/mfa_required.txt', {
        headers:{
            'Accept': 'text/plain',
            'Cache-Control': 'no-store'
        }
    })
    .then(response => response.blob())
    .then(blob => {
        if(blob && blob.type !== 'text/plain') return;
        blob.text().then(key => { if(key) Vue.prototype.$isMFARequired = key.trim() != "false" });
    });
}
