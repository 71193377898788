class UserStorage {
    #instance;

    constructor(instance){
        this.#instance = instance;
        this.storage = {};
        this.init();
    }

    init(){
        this.#instance.prototype.$http.get('/storage')
        .then(res =>{
            res.data.data.forEach(item => {
                this.storage[item.key] = item.value;
            });
        });
    }

    async set(key, value){
        if(key in this.storage){
            await this.#instance.prototype.$http.put(`/storage/${key}`, {
                value: value
            });
        }
        else {
            await this.#instance.prototype.$http.post('/storage', {
                key: key,
                value: value
            });
        }

        this.storage[key] = value;
        // this.#instance.prototype.$snack.show({text: 'Setting updated and saved.'});
    }

    get(key){
        return this.storage[key];
    }
}

export default UserStorage;