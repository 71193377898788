import Vue from 'vue';
import { updateMappedNames } from '@/utils';

class SessionService {

    constructor(){
        this.user = null;
        this.authUrl = `${process.env.VUE_APP_AUTH_URL}`;
        this.permissions = [];
        this.isAuthenticated = false;
        this.isRefreshing = false;
        this.revokeTries = Number(sessionStorage.revokeTries) || 0;
    }

    async initSession(){
        this.isAuthenticated = false;
        const service = this;
        return Vue.prototype.$http.get(`/user`, {timeout: 5000, skipPendingList: true})
        .then(res => {
            const userData = res?.data;
            if (typeof userData === 'string') return Promise.reject();
            this.setPermissions(userData.permissions);
            this.user = {
                username: userData.username,
                email: userData.email,
                id: userData.id,
                company_id: userData.company.id,
                parent_id: userData.company?.parent_id || null,
                company_name: userData.company.name,
                role_id: userData.role.id,
                role: userData.role.name,
                role_title: userData.role.title,
                avatar_path: userData.avatar_path,
                terms_conditions: JSON.parse(localStorage.getItem('terms_conditions')) || userData.terms_conditions,
                mfa_type: userData.mfa_type,
                exp: userData.exp,
                can: (action) => service.hasPermission(action)
            };
            Vue.prototype.$user = this.user;
            this.isAuthenticated = true;

            updateMappedNames({
                text: this.user.company_name || 'Current user company',
                value: this.user.company_id
            }, 'company_id');

            sessionStorage.removeItem('revokeTries');

            return Promise.resolve(this.user);
        }).catch(error => {
            if (error === undefined) {
                if(navigator.onLine){
                    return Promise.reject({ status: 408, data: { errors: [{ code: 'Request Timeout' }] } })
                }
                return Promise.reject({ data: { errors: [{ code: 'You are offline. Please make sure to check network cables, modem, and router or reconnecting to Wi-Fi and try again' }] } })
                
            }
            if (error?.status > 401) return Promise.reject(error);
            return this.refreshToken();
        })
    }

    async unauth(url){
        url = url || this.authUrl;
        let redirect_url;

        try {
            redirect_url = new URL(url);
        } catch (error) {
            redirect_url = new URL(location.origin + url);
        }

        // redirect_url.searchParams.set('continue_url', location.href);

        if(process.env.NODE_ENV === 'review'){
            const merge_id = location.href.match(/\d+/g)[0];
            redirect_url.searchParams.set('continue_review_id', merge_id);
        }

        await this.clearCache();
        window.location.replace(redirect_url.href);
    }

    async clearCache(){
        if(!window.isSecureContext) return;
        try {
            const q = [];
            const keys = await caches.keys();

            for (let key of keys) {
                q.push(caches.delete(key));
            }

            await Promise.all(q);
        } catch (error) {
            return;
        }
        
    }

    async refreshToken(request){
        if (this.isRefreshing) return;
        this.isRefreshing = true;
        this.isAuthenticated = false;
        try {
            const res = await Vue.prototype.$http({ method: 'post', url: `${this.authUrl}/token/refresh`, baseURL: '/', timeout: 5000 });
            this.isRefreshing = false;
            this.isAuthenticated = true;

            Vue.prototype.$eventHub.$emit('token-refreshed');
            
            if(window.socket){
                window.socket.close();
                window.socket = null;
            }

            if (this.user && request) {
                if (request.method === 'get') {
                    Vue.prototype.$eventHub.$emit('reload-data');
                } 
                // else {
                //     if (typeof request.data === 'string') {
                //         request.data = JSON.parse(request.data);
                //     }
                //     Vue.prototype.$http(request);
                // }
            } 
            else {
                Vue.prototype.$initApp();
            }
            return res;
        } catch (error) {
            this.revokeToken();
        }
    }

    revokeToken = async () =>{
        try {
            const locationParams = new URLSearchParams(location.search);
            const paramsWhitelist = ['client_id', 'redirect_uri'];
            const postParams = {}

            paramsWhitelist.forEach(param => {
                if (locationParams.has(param)) {
                    postParams[param] = locationParams.get(param);
                }
            });

            const res = await Vue.prototype.$http({method: 'post', url:`${this.authUrl}/token/revoke`,  baseURL: '/', data: postParams });
            this.unauth(res.data?.location);
        } catch (error) {
            this.revokeTries++;
            sessionStorage.setItem('revokeTries', this.revokeTries);
            if (this.revokeTries >= 3) {
                Vue.prototype.$initMaintenance();
                this.revokeTries = 0;
                sessionStorage.removeItem('revokeTries');
                return;
            }
            this.unauth();
        } 
    }

    resendActivation = async() =>{
        try {
            await Vue.prototype.$http({ method: 'post', url: `${this.authUrl}/user/activation/resend`, baseURL: '/' });
            Vue.prototype.$snack.show({text: 'Email confirmation sent successfully'})
        } catch (error) {
            Vue.prototype.$snack.show({ text: `Failed to send email confirmation (${error.statusText})` })
        } 
    }

    setPermissions(permissions) {
        this.permissions = permissions || [];
    }

    hasPermission(action) {
        if(!action) return true;
        return this.permissions?.includes(action);
    }

    isSessionValid(){
        if(!this.user) return false;
        const token_exp = this.user.exp * 1000;
        const now = new Date().getTime();
        return token_exp > now;
    }
}

export default new SessionService();