// import sessionService from '@/services/sessionService.js';
// import {timeout} from '@/utils';
export default {
    data() {
        return {
            $_errors: null,
            $_res: null,
            $_fieldErrors: {},
            $_waiting: false
        };
    },
    methods: {
        async $_submit(endpoint, form) {
            if (this.$_waiting) return;
            if (form.schema?.id === 'editCompanyName'){
                if (form.schema.defaults.name === form.data.name) {
                    this.$snack.show({ text: 'Please input different value to save changes.' });
                    return;
                }
            }
            this.$_waiting = true;
            this.$data.$_waiting = true;
            if(!form.method) form.method = 'post';

            this.$data.$_errors = null;
            this.$data.$_fieldErrors = {};

            this.$http({url: endpoint, data: form.data, method: form.method, baseURL: form.baseURL})
                .then(res => handleSuccess(res, this, form, endpoint))
                .catch(res => handleError(res, this, form, endpoint));
        },

        $_upload(endpoint, form){  
            if (this.$_waiting) return;
            this.$_waiting = true;
            this.$data.$_waiting = true;
            this.$data.$_errors = null;
            this.$data.$_fieldErrors = {};

            const formData = new FormData();
            const config = {
                headers: { 'Content-Type': 'multipart/form-data' }
            }

            for (const key in form.data) {
                const field = form.data[key];
                
                if(Array.isArray(field)){
                    field.forEach((value, i) => { 
                        if (String(value) === '[object Object]' && Object.keys(value).length){
                            Object.keys(value).forEach(valueKey => {
                                formData.append(`${key}[${i}][${valueKey}]`, value[valueKey]);
                            });
                        }else{  
                            formData.append(`${key}[]`, value) 
                        }
                        
                    });
                }else{
                    formData.append(key, field);
                }
            }


            this.$http.post(endpoint, formData, config)
                .then(res => handleSuccess(res, this, form, endpoint))
                .catch(res => handleError(res, this, form, endpoint, true));

        }
    }
}
function handleSuccess(res, instance, form, endpoint){
    instance.$data.$_waiting = false;
    instance.$_waiting = false;

    if (isMfaRequired(res)) {
        handleMfaRequest(res, endpoint, form, instance)
        return;
    }

    if (res?.data?.meta?.channel) {
        const items = res.data?.data || instance.data?.selection || form.selection;
        const hasAutoClose = !items;
        // instance.$modals.close();

        if(!form?.leaveOpen){
            instance.$modals.close();
        }
        instance.$modals.open({
            title: 'Action status',
            component: 'DeviceSocketAction',
            props: {
                channel: res.data.meta.channel,
                selection: items,
                autoClose: hasAutoClose,
                res: res,
                tableRef: form.schema?.tableRef,
                onClose: form.onClose,
                company_id: form.data?.company_id
            }
        });
    } else {
        if(form?.successMsg){
            instance.$snack.show({ text: form.successMsg });
        }
        else{
            instance.$snack.show({ text: instance.$resMsg(res) });
        }
        if(!form?.leaveOpen){
            instance.$modals.close();
        }
        instance.$data.$_res = res.data;

        if (form.onSuccess) {
            form.onSuccess(instance);
        }

        if(form.reload === false) return;
        
        if(form.schema?.tableRef){
            const tableRef = form.schema.tableRef;
            tableRef.getData(true);
        }else{
            instance.$eventHub.$emit('reload-data', {type: 'form'});
        }

        
    }
}
async function handleError(res, instance, form, endpoint, isUpload = false) {
    instance.$data.$_waiting = false;
    instance.$_waiting = false;
    if (typeof res?.data === 'object') {
        
        if(res.status === 401 && res.data?.errors[0]?.message !== 'Unauthorized. (XSRF)'){
            // await sessionService.refreshToken(); already refreshing in httpService interceptor
            if(isUpload){
                instance.$_upload(endpoint, form);
            }else{
                instance.$_submit(endpoint, form);
            }
        }
        
        if (isMfaRequired(res)){
            handleMfaRequest(res, endpoint, form, instance)
            return;
        }

        if(form.onError){
            form.onError(res);
            return;
        }

        if (res.status === 423) {
            instance.$modals.confirm(res.data.message, {input: { label: 'Password', type: 'password' }})
                .then(value =>
                    instance.$http.post('/password-confirmation', { password: value })
                        .then(() => instance.$_submit(endpoint, form))
                        .catch((res) => instance.$data.$_errors = { message: [res.data.message] })

                ).catch(() => { });

        } else {            
            if(Array.isArray(res.data?.errors)){
                let errors = [];
                let fileErrors = [];
                const isBulk = Array.isArray(form.data.data);
                // const sourceRegex = /(^(data){1}.(\d+){1}.([a-z]+)$)|(^[a-z]+$)/g;
                
                const formKeys = isBulk
                    ? Object.keys(form.data.data[0])
                    : Object.keys(form.data);

                res.data.errors.forEach(error => {

                    if(error.code === 'UNAUTHORIZED') return;

                    const source = isBulk
                        ? error.source?.split(".")[2] || ""
                        : error.source || "";

                    const isVisibleInForm = document.querySelector(`.form-field[data-key='${source}']`)?.offsetParent;

                    if (source.includes("/") && isUpload){
                        const sourceSplit = source.split("/");
                        const lineOf = sourceSplit[2].split(":");
     
                        fileErrors.push({
                            source: sourceSplit[0],
                            message: `${error.message} (${sourceSplit.pop()}, line ${lineOf[0]} ${lineOf[1] ? ` in ${lineOf[1]}` : ''})`
                        });
                    }
                    else if (source && source !== 'id' && (formKeys.includes(source) || isVisibleInForm)){
                        
                        if(isBulk && source && error.message){
                            error.message = error.message.replace(/data\.\d+\.[a-z]+/g, error.source.split(".")[2]);
                        }

                        instance.$set(instance.$data.$_fieldErrors, form.combineBulkSources ? source : error.source, error);
                        if (source === 'password' && formKeys.includes('password_confirmation')){
                            instance.$set(instance.$data.$_fieldErrors, 'password_confirmation', {message: ''});
                        }
                        
                    }
                    else{
                        errors.push(error);
                    }
                });

                if(errors.length){
                    instance.$data.$_errors = errors;
                } 
                if(fileErrors.length){
                    instance.$set(instance.$data.$_fieldErrors, 'file', fileErrors);
                }
            }
            else{
                if(res.data.errors?.source){
                    instance.$set(instance.$data.$_fieldErrors, res.data.errors.source, res.data.errors);
                }
                else{
                    instance.$data.$_errors = res.data?.errors || {error: [res.statusText]};
                }
            }
            // instance.$data.$_errors = res.data.errors
            //     ? res.data.errors
            //     : { message: [res.data.message || res.statusText] };
        }

        document.querySelector('.main-modal .modal-content')?.scrollTo(0, 0);

    } else {
        const defaultErrorMsg = isUpload 
            ? 'Failed to connect to server. Try reuploading your file or try again later'
            : 'Failed to connect to server';
        instance.$data.$_errors = { error: [defaultErrorMsg] }
    }
}

function handleMfaRequest(res, endpoint, form, instance){
    const meta = res.data.meta;
    const useFormData = res.config.data instanceof FormData;

    if (meta.mfa_method === 'email') {
        instance.$modals.open({
            title: 'Enter authentication code',
            subtitle: `The verification code has been sent to ${instance.$user.email}.
            Please check your inbox and complete authentication step.`,
            form: {
                onSubmit: (formData, formInstance) => {
                    let dataForm = form;
                    dataForm.data.session_code = formData.code;
                    dataForm.data.session_uuid = meta.session_uuid;
                    dataForm.leaveOpen = false;  
                    dataForm.reload = true;
                    dataForm.onError = (response) => {
                        delete dataForm.data?.session_uuid;
                        delete dataForm.data?.session_code;
                        if(Array.isArray(response.data.errors)){
                            let confirmFailed = (response?.data?.errors || []).find(error => error.code === 'MFA_REQUIRED' || error.code === "CONFIRMATION_FAILED");
                            if(confirmFailed){
                                delete dataForm.onError;
                                handleError(response, formInstance, dataForm, endpoint, true);
                            }
                            else{
                                delete dataForm.onError;
                                handleError(response, instance, dataForm, endpoint, true);
                                formInstance.$modals.close();
                            }                            
                        }
                        else {
                            delete dataForm.onError;
                            handleError(response, instance, dataForm, endpoint, true);
                        }
                    }
                    if(useFormData){
                        formInstance.$_upload(endpoint, dataForm);
                    } else{
                        formInstance.$_submit(endpoint, dataForm);
                    }
                    
                },
                submitBtn: 'Confirm',
                fields: [
                    {label: 'Code', key: 'code', required: true}
                ]
            }
        });
    }

    if(meta.mfa_method === 'teltonika_id'){
        form.data.session_uuid = meta.session_uuid;
        let data = form.data;
        delete data?.session_uuid;
        instance.$modals.open({
            title: 'Teltonika ID verification',
            component: "MfaAuth",
            props: {...meta, endpoint: endpoint, data: data, selection: form.selection, useFormData: useFormData,
                onMfaError: (response) => {
                    handleError(response, instance, form, endpoint, true);
                }
            }
        });
    }
}

function isMfaRequired(res){
    return (res?.data?.errors || []).find(error => error.code === 'MFA_REQUIRED');
}
