export const adminRoles = ['admin', 'end_user', 'readonly_admin', 'read_only'];
export const superAdminRoles = [...adminRoles, 'support'];

const roles = {
    'super_admin': { id: 1, name: 'System Administrator', canInviteRoles: superAdminRoles },
    'admin': { id: 2, name: 'Administrator', canInviteRoles: adminRoles, description: 'Administrators have full control over their company devices, users, and any devices / users that belong to their subsidiary companies. Administrators are able to create new users and subsidiary companies.' },
    'end_user': { id: 3, name: 'End Client', description: "End clients can only see and interact with devices and users that belong to their specific company. End clients cannot create subsidiary companies." },
    'readonly_admin': { id: 6, name: 'Advanced Guest', description: "Advanced guest role is used for demonstration purposes. They cannot perform any actions and cannot interact with any device, user or company. Advanced guests can view their company’s information that they are assigned to, as well as any subsidiary company’s, which is below in the company hierarchy."},
    'read_only': { id: 4, name: 'Guest', description: "Guest role is used for demonstration purposes. They cannot perform any actions and cannot interact with any device, user or company. Basic guests can only view their company’s information that they are assigned to." },
    'support': { id: 5, name: 'Support'}
}

export function getDefaultRolesArray(userRole){
    const userRoleDef = roles[userRole] || roles['admin'];
    return Object.keys(roles)
        .filter(roleKey => {
            if (!userRoleDef?.canInviteRoles) return false;
            if (userRoleDef?.canInviteRoles?.includes(roleKey)) return true;
        })
        .map(roleKey => {
            return {
                value: roleKey,
                name: roles[roleKey].name,
                hint: roles[roleKey].description
            }
        })
}

export default roles;